import { Box, Chip, Radio, Typography } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import { FileList, PeerGroupThumbnail } from '../../components'
import { BackgroundPhotoOption, Options } from '../../providers/Options/types'
import { PeerGroupDetails } from '../../providers/PeerGroups/types'
import { ProviderDetails } from '../../providers/ProviderDetails/types'
import { themeColors } from '../../theme'
import { dateFormatters } from '../HelperFunctions'
import { Field, FieldType } from './types'

enum DateOrTime {
  Date,
  Time,
}

export interface ViewProps {
  field: Field
  options?: Options[]
  value: any
  dateOrTime?: DateOrTime
}

const BasicView = ({ value }: ViewProps) => (
  <Typography
    sx={{
      fontWeight: 500,
      fontSize: '14px',
      color: themeColors.gray40,
    }}
  >
    {value}
  </Typography>
)

const AutocompleteMultipleView = ({ field, options, value }: ViewProps) => (
  <Box sx={{ display: 'flex', flexFlow: 'row wrap' }}>
    {value?.map((val: any, index: number) => (
      <Box key={`${field.backendKey}-${index}`}>
        <Chip
          sx={{ border: `1px solid ${themeColors.gray10}` }}
          label={
            options?.find((opt: Options) => opt.apiId === val || opt === val)
              ?.label || (field.chipLabelKey ? val[field.chipLabelKey] : val)
          }
        />
      </Box>
    ))}
  </Box>
)

const AutocompleteView = ({ options, value }: ViewProps) => {
  return (
    <Box>
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: '14px',
          color: themeColors.gray40,
        }}
      >
        {options?.find((opt: Options) => opt.apiId === value || opt === value)
          ?.label || value}
      </Typography>
    </Box>
  )
}

const QuestionGroup = ({ field, value }: ViewProps) => {
  const fields: string[] = field.backendKey.split(',')

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
      }}
    >
      {fields.map((fieldVal: string, i: number) => (
        <Box
          key={field.backendKey + i}
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            width: '100%',
            padding: '5px',
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '14px',
              color: themeColors.gray40,
              paddingRight: '5px',
            }}
          >
            {value && value[i]}
          </Typography>

          <Typography variant='caption'>{field.subLabels![i] ?? ''}</Typography>
        </Box>
      ))}
    </Box>
  )
}

const RadioButtonView = ({ field, options, value }: ViewProps) => (
  <Box>
    <Box sx={{ display: 'flex' }}>
      {options?.map((opt: Options) => (
        <Box
          key={opt.label}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Radio
            sx={{
              color: themeColors.gray70,
              '&.Mui-checked': {
                color: themeColors.gray70,
              },
            }}
            checked={
              value === undefined
                ? opt.value === field.initialValue
                : opt.value === value
            }
            value={`${opt.value}`}
            disabled
            name={`${field.label}-radio`}
            inputProps={{ 'aria-label': opt.label }}
          />
          <Typography variant='caption'>{opt.label}</Typography>
        </Box>
      ))}
    </Box>
  </Box>
)

export const DateTimewView = ({ value, dateOrTime }: ViewProps) => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Box>
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: '14px',
          color: themeColors.gray40,
        }}
      >
        {dateOrTime === DateOrTime.Date &&
          dateFormatters.shortenedCleanDate(value)}
        {dateOrTime === DateOrTime.Time && dateFormatters.time(value)}
      </Typography>
    </Box>
  </LocalizationProvider>
)

const MediaView = ({ value }: ViewProps) => {
  return (
    <Box>
      {value?.length ? (
        <FileList files={value} fullFileNames />
      ) : (
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '14px',
            color: themeColors.gray40,
          }}
        >
          No uploaded media
        </Typography>
      )}
    </Box>
  )
}

const ImageSelectedView = ({
  value,
  existingValues,
  options,
}: ViewProps & {
  existingValues: PeerGroupDetails | ProviderDetails | null
}) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'flex-start',
      minHeight: '280px',
      width: '100%',
      minWidth: '100%',
      justifyContent: 'flex-start',
    }}
  >
    <Box
      sx={{
        minHeight: '280px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        position: 'relative',
        marginLeft: '10px',
        minWidth: '150px',
      }}
    >
      <PeerGroupThumbnail
        imageSrc={
          (
            (options || []).find(
              (opt) => opt.apiId === value
            ) as BackgroundPhotoOption
          )?.storageKey
        }
        isPreviewMode={true}
        title={
          (existingValues as PeerGroupDetails)?.groupName ||
          process.env.REACT_APP_PLACEHOLDER_TITLE
        }
      />
    </Box>
  </Box>
)

export type GetViewFieldBasedOnTypeProps = {
  field: Field
  options: Options
  value: any | null
  existingValues: PeerGroupDetails | ProviderDetails | null
}

export const getViewFieldBasedOnType = ({
  field,
  options,
  value,
  existingValues,
}: GetViewFieldBasedOnTypeProps): JSX.Element | null => {
  if (!existingValues) return null
  switch (field.fieldType) {
    case FieldType.Autocomplete:
      return (
        <AutocompleteView
          {...{ field, value }}
          options={
            field.optionsSelector ? (options as any)[field.optionsSelector] : []
          }
        />
      )
    case FieldType.Multiselect:
      return (
        <AutocompleteMultipleView
          {...{ field, value }}
          options={
            field.optionsSelector ? (options as any)[field.optionsSelector] : []
          }
        />
      )
    case FieldType.Radio:
      return (
        <RadioButtonView options={field.options || []} {...{ field, value }} />
      )
    case FieldType.Date:
      return (
        <DateTimewView {...{ field, value }} dateOrTime={DateOrTime.Date} />
      )
    case FieldType.Time:
      return (
        <DateTimewView {...{ field, value }} dateOrTime={DateOrTime.Time} />
      )
    case FieldType.Media:
      return <MediaView {...{ field, value }} />
    case FieldType.ImageSelector:
      return (
        <ImageSelectedView
          {...{ field, value, existingValues }}
          options={
            field.optionsSelector ? (options as any)[field.optionsSelector] : []
          }
        />
      )
    case FieldType.QuestionGroup:
      return <QuestionGroup {...{ field, value }} />
    default:
      return <BasicView {...{ field, value }} />
  }
}
