import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
export const useStyles = makeStyles()((theme: Theme) => ({
  mainView: {
    backgroundColor: theme.palette.primary.light,
    height: `calc(100vh - ${theme.spacing(6)})`,
    overflow: 'auto',
    maxWidth: '100%',
    minWidth: '100%',
    width: '100%',
    padding: theme.spacing(1.5),
  },
  viewWrapper: {
    width: '100%',
    minWidth: '100%',
    height: `100%`,
  },
}))
