import { Box, Button, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'

import { Modal } from '../../../../components'
import { usePeerGroups } from '../../../../providers/PeerGroups/PeerGroups.provider'
import { PeerGroupSession } from '../../../../providers/PeerGroups/types'

interface AddSessionMediaModalProps {
  setShowAddSessionMediaModal: React.Dispatch<React.SetStateAction<boolean>>
  showAddSessionMediaModal: boolean
}

export const AddSessionMediaModal = ({
  showAddSessionMediaModal,
  setShowAddSessionMediaModal,
}: AddSessionMediaModalProps) => {
  const {
    peerGroupDetails,
    addMediaToSinglePeerGroupSession,
    getPeerGroupDetails,
  } = usePeerGroups()

  const [selectedSessionId, setSelectedSessionId] = useState('')
  const [selectedSession, setSelectedSession] = useState<PeerGroupSession>({
    id: 0,
    start: '',
    end: '',
    mediaIds: [],
  })

  const [checkedMedia, setCheckedMedia] = useState<number[]>([])

  const [dstDateCorrectedSessions, setDstDateCorrectedSessions] = useState<
    PeerGroupSession[]
  >([])

  const handleSelectedMedia = (media: number) => {
    setCheckedMedia((prevCheckedMedias) => {
      if (prevCheckedMedias.includes(media)) {
        return prevCheckedMedias.filter((i) => i !== media)
      } else {
        return [...prevCheckedMedias, media]
      }
    })
  }

  useEffect(() => {
    setSelectedSessionId(String(peerGroupDetails?.sessions[0].id))
  }, [peerGroupDetails])

  useEffect(() => {
    const matchingSession = peerGroupDetails?.sessions.find(
      (session) => session.id === Number(selectedSessionId)
    )
    if (matchingSession) {
      setSelectedSession(matchingSession)
    }
  }, [selectedSessionId])

  useEffect(() => {
    setCheckedMedia(selectedSession.mediaIds)
  }, [selectedSession])

  const handleSave = async () => {
    if (checkedMedia.length > 0) {
      await addMediaToSinglePeerGroupSession(
        Number(selectedSessionId),
        checkedMedia
      )
      await getPeerGroupDetails(peerGroupDetails?.peerGroupId)
    }
    setShowAddSessionMediaModal(false)
  }

  const isDST = (date: Date) => {
    const jan = new Date(date.getFullYear(), 0, 1)
    const jul = new Date(date.getFullYear(), 6, 1)
    const stdTimezoneOffset = Math.max(
      jan.getTimezoneOffset(),
      jul.getTimezoneOffset()
    )

    return date.getTimezoneOffset() < stdTimezoneOffset
  }

  useEffect(() => {
    if (peerGroupDetails?.sessions?.length) {
      const copyOfSessions = [...peerGroupDetails.sessions]
      // if it is DST, then shift the not DST times up an hour
      if (isDST(new Date(Date.now()))) {
        copyOfSessions.forEach((session) => {
          if (!isDST(new Date(session.start))) {
            const sessionStartDate = new Date(session.start)
            const sessionEndDate = new Date(session.end)
            sessionStartDate.setHours(sessionStartDate.getHours() + 1)
            sessionEndDate.setHours(sessionEndDate.getHours() + 1)
            session.start = sessionStartDate.toISOString()
            session.end = sessionEndDate.toISOString()
          }
        })
      }
      // if it's not DST then shift the times in DST down an hour
      if (!isDST(new Date(Date.now()))) {
        copyOfSessions.forEach((session) => {
          if (isDST(new Date(session.start))) {
            const sessionStartDate = new Date(session.start)
            const sessionEndDate = new Date(session.end)
            sessionStartDate.setHours(sessionStartDate.getHours() - 1)
            sessionEndDate.setHours(sessionEndDate.getHours() - 1)
            session.start = sessionStartDate.toISOString()
            session.end = sessionEndDate.toISOString()
          }
        })
      }
      setDstDateCorrectedSessions(copyOfSessions)
    }
  }, [peerGroupDetails])

  return (
    <Modal
      open={showAddSessionMediaModal}
      setOpen={setShowAddSessionMediaModal}
      title={'ADD MEDIA TO PEER GROUP SESSIONS'}
      width='40%'
      ModalContent={
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'space-between',
            justifyContent: 'space-between',
          }}
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: 'max-content',
              alignSelf: 'center',
            }}
          >
            <h3 style={{ marginRight: '15px' }}>Session Date: </h3>
            <select onChange={(e) => setSelectedSessionId(e.target.value)}>
              {dstDateCorrectedSessions.map((session) => {
                return (
                  <option key={session.id} value={session.id}>
                    {dayjs(session.start).format('MM/DD/YYYY hh:mmA')}
                  </option>
                )
              })}
            </select>
          </Box>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignContent: 'center',
              height: '200px',
              overflowY: 'scroll',
              padding: '10px 0',
            }}
          >
            {peerGroupDetails?.media?.map((media, i) => {
              return (
                <Box
                  key={`peer-group-media-${i}`}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignContent: 'center',
                    marginBottom: '5px',
                    width: '50%',
                    minWidth: '350px',
                    alignSelf: 'center',
                  }}
                >
                  <span
                    style={{
                      fontSize: '16px',
                      lineHeight: '20px',
                      marginBottom: '5px',
                      textAlign: 'left',
                      width: '300px',
                      padding: '0 10px',
                      wordWrap: 'break-word',
                    }}
                  >
                    {media.fileName}
                  </span>
                  <input
                    type='checkbox'
                    checked={media.id ? checkedMedia.includes(media.id) : false}
                    onChange={() => {
                      console.log('media.id', media.id)
                      if (media.id) {
                        handleSelectedMedia(media.id)
                      }
                    }}
                    className='form-checkbox h-5 w-5 text-blue-600'
                    style={{
                      width: '20px',
                      height: '20px',
                      margin: '0 10px',
                    }}
                  />
                </Box>
              )
            })}
          </div>
          <Button
            variant='contained'
            onClick={() => {
              handleSave()
            }}
            style={{ width: '50px', alignSelf: 'center', marginTop: '10px' }}
          >
            Save
          </Button>
        </Box>
      }
    />
  )
}
