import { Box, Typography } from '@mui/material'

import VRLogo from '../../assets/icon.vr.svg'
import { themeColors } from '../../theme'

export const PeerGroupThumbnail = ({
  imageSrc,
  title = process.env.REACT_APP_PLACEHOLDER_TITLE,
  isPreviewMode = false,
}: {
  imageSrc: string
  title?: string | undefined
  isPreviewMode?: boolean
}) => (
  <Box
    sx={{ position: 'relative', width: 150, height: 200, borderRadius: '10px' }}
  >
    <Box
      component='img'
      sx={{
        borderRadius: '10px',
        objectFit: 'cover',
        position: 'relative',
        width: 150,
        height: 200,
      }}
      src={imageSrc}
      alt=''
    />
    <Box
      sx={{
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
        position: 'absolute',
        width: 150,
        height: 160,
        left: 0,
        bottom: 0,
        background:
          'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)',
      }}
    />

    {isPreviewMode && (
      <Box
        sx={{
          position: 'absolute',
          width: 150,
          height: 200,
          left: 0,
          top: 0,
        }}
      >
        <Typography
          sx={{
            backgroundColor: themeColors.white,
            padding: '6.5px 10px',
            borderRadius: 3,
            textAlign: 'center',
            position: 'absolute',
            left: 10,
            top: 10,
            height: 25,
            fontWeight: 900,
            fontSize: 12,
            lineHeight: 1,
            minWidth: 75,
            color: themeColors.green,
          }}
        >
          Available
        </Typography>
        <Box
          sx={{
            position: 'absolute',
            left: 10,
            bottom: 10,
          }}
        >
          <Box
            sx={{
              filter: 'grayscale(1) brightness(0) invert(1)',
              marginBottom: '5px',
            }}
            component='img'
            src={VRLogo}
            alt=''
          />
          <Typography
            sx={{
              fontWeight: 900,
              fontSize: 18,
              lineHeight: 1.1,
              color: themeColors.white,
              width: 'calc(100% - 10px)',
            }}
          >
            {title}
          </Typography>
        </Box>
      </Box>
    )}
  </Box>
)
