export enum SaveProgress {
  NotStarted,
  InProgress,
  Completed,
}

export type AvatarPickerSpecs = {
  image?: string | File
  allowZoomOut: boolean
  position: { x: number; y: number }
  scale: number
  rotate: number
  borderRadius: number
  width: number
  height: number
}
