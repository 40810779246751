import * as yup from 'yup'

import {
  FieldType,
  OptionsSelector,
  SectionFields,
} from '../../utilities/Forms/types'

export const PROVIDER_DETAILS_FIELDS: SectionFields = [
  {
    sectionLabel: 'Basic Information',
    hasInstructions: true,
    fields: [
      {
        label: 'First Name',
        backendKey: 'firstName',
        isPatientVisible: false,
        required: true,
        isEditable: false,
        validation: yup
          .string()
          .required('First Name is required')
          .typeError('First Name is required'),
      },
      {
        label: 'Last Name',
        backendKey: 'lastName',
        isPatientVisible: true,
        required: true,
        isEditable: false,
        validation: yup
          .string()
          .required('Last Name is required')
          .typeError('Last Name is required'),
      },
      {
        label: 'Middle Initial',
        backendKey: 'middleInitial',
        isPatientVisible: false,
        required: false,
        isEditable: false,
        validation: yup.string(),
      },
      {
        label: 'Preferred Name',
        backendKey: 'preferredFirstName',
        isPatientVisible: true,
        required: true,
        isEditable: true,
        validation: yup
          .string()
          .required('Preferred Name is required')
          .typeError('Preferred Name is required'),
      },
      {
        label: 'Email',
        backendKey: 'emailAddress',
        isPatientVisible: false,
        required: true,
        isEditable: false,
        validation: yup
          .string()
          .email('Email address format must be valid (name@example.com)')
          .typeError('Email is required')
          .required('Email is required'),
      },
      {
        label: 'Company Phone #',
        backendKey: 'companyPhone',
        isPatientVisible: false,
        required: true,
        isEditable: true,
        validation: yup
          .string()
          .required('Company Phone # is required')
          .typeError('Company Phone # is required'),
      },
      {
        label: 'Personal Phone #',
        backendKey: 'personalPhone',
        isPatientVisible: false,
        required: true,
        isEditable: false,
        validation: yup
          .string()
          .required('Personal Phone # is required')
          .typeError('Personal Phone # is required'),
      },
    ],
  },
  {
    sectionLabel: 'Address',
    fields: [
      {
        label: 'Address 1',
        backendKey: 'addressLine1',
        isPatientVisible: false,
        required: true,
        isEditable: true,
        validation: yup
          .string()
          .required('Address is required')
          .typeError('Address is required'),
      },
      {
        label: 'Address 2',
        backendKey: 'addressLine2',
        isPatientVisible: false,
        required: false,
        isEditable: true,
        validation: yup.string().nullable(),
      },
      {
        label: 'City',
        backendKey: 'city',
        isPatientVisible: false,
        required: true,
        isEditable: true,
        validation: yup
          .string()
          .required('City is required')
          .typeError('City is required'),
      },
      {
        label: 'State',
        backendKey: 'state',
        isPatientVisible: false,
        required: true,
        isEditable: true,
        optionsSelector: OptionsSelector.StatesWithoutId,
        fieldType: FieldType.Autocomplete,
        validation: yup
          .string()
          .required('State is required')
          .typeError('State is required'),
      },
      {
        label: 'Zip Code',
        backendKey: 'zipCode',
        isPatientVisible: false,
        required: true,
        isEditable: true,
        validation: yup
          .string()
          .required('Zip Code is required')
          .typeError('Zip Code is required'),
      },
      {
        label: 'Time Zone',
        backendKey: 'timeZone',
        isPatientVisible: false,
        required: true,
        isEditable: true,
        optionsSelector: OptionsSelector.TimeZones,
        fieldType: FieldType.Autocomplete,
        validation: yup
          .string()
          .required('Time Zone is required')
          .typeError('Time Zone is required'),
      },
    ],
  },
  {
    sectionLabel: 'Professional Information',
    sectionSplit: true,
    fields: [
      {
        label: 'Bio',
        backendKey: 'bio',
        isPatientVisible: true,
        required: true,
        isEditable: true,
        minWidthPercent: 100,
        fieldType: FieldType.TextArea,
        validation: yup
          .string()
          .required('Bio is required')
          .typeError('Bio is required'),
      },
      {
        label: 'Credentials',
        backendKey: 'credential',
        isPatientVisible: true,
        required: false,
        isEditable: true,
        optionsSelector: OptionsSelector.ProviderCredentials,
        fieldType: FieldType.Autocomplete,
        validation: yup.number().nullable(),
      },
      {
        label: 'States Privileged',
        backendKey: 'priviledgedStates',
        displayKey: 'priviledgedStateDisplay',
        isPatientVisible: false,
        required: false,
        isEditable: true,
        optionsSelector: OptionsSelector.States,
        fieldType: FieldType.Multiselect,
        minWidthPercent: 60,
        validation: yup.array().of(
          yup.object().shape({
            name: yup.mixed(),
            abbreviation: yup.mixed(),
            stateId: yup.number(),
          })
        ),
        chipLabelKey: 'abbreviation',
      },
    ],
  },
]
