import 'plyr-react/plyr.css'

import { Box, Typography } from '@mui/material'
import { Dispatch, SetStateAction } from 'react'

import { Modal } from '../../../../components'
import { useVrHeadset } from '../../../../providers/VrHeadset/VrHeadset.provider'

interface JoinVRHelpModalProps {
  showJoinHelpModal: boolean
  setShowJoinHelpModal: Dispatch<SetStateAction<boolean>>
}

export const JoinVRHelpModal = ({
  showJoinHelpModal,
  setShowJoinHelpModal,
}: JoinVRHelpModalProps) => {
  const { joinInstructionVideo } = useVrHeadset()
  const instructions = [
    `On your Virtual Reality (VR) headset, press the <b>POWER</b> button to turn on the device.`,
    `Pick up your VR controllers and put on your headset.`,
    `In VR, select <b>APPS</b> from the main navigation tray.`,
    `Select the <b>NORTH-STAR CARE VR APP</b> in the list and click to open`,
    `Edit your nickname and/or avatar (optional), then select <b>PEER GROUP MEETING</b>.`,
    `You will be placed in the VR experience and the meeting will start once the North-Star Care moderator joins.`,
  ]
  return (
    <Modal
      open={showJoinHelpModal}
      setOpen={setShowJoinHelpModal}
      title={'JOIN VR INSTRUCTIONS'}
      ModalContent={
        <Box sx={{ margin: '35px', height: '100%' }}>
          <Box sx={{ marginBottom: '25px' }}>
            <Typography sx={{ fontSize: '16px', lineHeight: '19px' }}>
              For instructions on how to join VR peer support meetings, watch
              the 2-minute video tutorial or see the written step by step
              instructions below.
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'stretch',
              alignContent: 'stretch',
            }}
          >
            <Box
              sx={{
                minHeight: '100%',
                width: '45%',
                padding: '5px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Box>
                <Typography sx={{ fontSize: '14x', lineHeight: '18px' }}>
                  VIDEO TUTORIAL
                </Typography>
                <Box sx={{ marginTop: '20px' }}>
                  {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                  <video width={'100%'} controls className='plyr-react plyr'>
                    <source src={joinInstructionVideo} type='video/mp4' />
                  </video>
                </Box>
              </Box>
            </Box>
            <Box sx={{ width: '55%', padding: '5px' }}>
              <Typography sx={{ fontSize: '14x', lineHeight: '18px' }}>
                STEP BY STEP INSTRUCTIONS
              </Typography>
              <ol
                style={{
                  fontWeight: '400',
                  fontSize: '16px',
                  lineHeight: '21px',
                }}
              >
                {instructions.map((instr, i) => (
                  <li key={'instruction-' + i}>
                    <Typography
                      sx={{
                        fontSize: '16px',
                        lineHeight: '19px',
                      }}
                      dangerouslySetInnerHTML={{ __html: instr }}
                    ></Typography>
                  </li>
                ))}
                <br />
                <Typography sx={{ fontSize: '16px', lineHeight: '19px' }}>
                  For additional VR support, please call (253) 357-6413.
                </Typography>
              </ol>
            </Box>
          </Box>
        </Box>
      }
    />
  )
}
