import { CognitoUser } from '@aws-amplify/auth'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { createContext, ReactNode, useContext } from 'react'

export interface UserContextType {
  user: CognitoUser | null
  signOut: (data?: any) => void
}

export const UserContext = createContext<UserContextType>({
  user: null,
  signOut: () => {},
})

interface UserProviderProps {
  children?: ReactNode
}

export const UserProvider = ({ children }: UserProviderProps) => {
  const { user, signOut } = useAuthenticator((context) => [
    context.user,
    context.signOut,
  ])

  return (
    <UserContext.Provider
      value={{
        user,
        signOut,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export const useUser = () => useContext(UserContext)
