import { Close } from '@mui/icons-material'
import { Box, IconButton, Popover } from '@mui/material'
import { ReactNode } from 'react'

import { themeColors } from '../../theme'
import { useStyles } from './DetailsPopout.styles'

export interface DetailsPopoutProps {
  id?: string
  children: ReactNode
  open: boolean
  handleClose: () => void
  anchorEl: any
  position?: {
    vertical: 'top' | 'center' | 'bottom' | number
    horizontal: 'left' | 'center' | 'right' | number
  }
  width: string
}

export const DetailsPopout = ({
  id,
  open,
  anchorEl,
  handleClose,
  position = {
    vertical: 'bottom',
    horizontal: 'center',
  },
  width,
  children,
}: DetailsPopoutProps) => {
  const { classes } = useStyles()
  return (
    <Popover
      classes={{ paper: classes.paper }}
      id={id}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={position}
      transformOrigin={{
        vertical: 0,
        horizontal: 'center',
      }}
      sx={{
        margin: 0,
        boxShadow: `0px 5px 10px 2px rgba(0, 0, 0, 0.25)`,
        border: `1px solid ${themeColors.gray60}`,
        borderRadius: '8px',
      }}
    >
      <Box
        sx={{
          margin: 0,
          borderRadius: '8px',
          minWidth: '300px',
          maxWidth: '400px',
          width: width,
          position: 'relative',
        }}
      >
        {children}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            right: 10,
          }}
        >
          <IconButton
            size='large'
            aria-haspopup='true'
            onClick={handleClose}
            color='primary'
          >
            <Close fontSize='medium' color='primary' />
          </IconButton>
        </Box>
      </Box>
    </Popover>
  )
}
