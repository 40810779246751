import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { Control, FieldValues, useForm } from 'react-hook-form'

import { useOptions } from '../../providers/Options/Options.provider'
import { Options } from '../../providers/Options/types'
import { useProviderDetails } from '../../providers/ProviderDetails/ProviderDetails.provider'
import { useVrHeadset } from '../../providers/VrHeadset/VrHeadset.provider'
import {
  getFieldValidationSchema,
  getInputFieldBasedOnType,
} from '../../utilities/Forms/SectionFields'
import { Field, Section, SectionFields } from '../../utilities/Forms/types'
import { getViewFieldBasedOnType } from '../../utilities/Forms/ViewFields'
import { dateFormatters } from '../../utilities/HelperFunctions'
import { uploadFileToAWS } from '../../utilities/Storage'
import { ProfileView } from './Profile.view'
import { PROVIDER_DETAILS_FIELDS } from './ProfileFields'
import { SaveProgress } from './types'

const Profile = () => {
  const {
    myProviderDetails,
    updateProvider,
    updateProviderPhoto,
    getMyProviderDetails,
  } = useProviderDetails()
  const { isPaired, unpairHeadset, getPairingCode, unpairError } =
    useVrHeadset()
  const { options } = useOptions()
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    resolver: yupResolver(getFieldValidationSchema(PROVIDER_DETAILS_FIELDS)),
  })

  const [profileDetailFields, setProfileDetailFields] = useState<SectionFields>(
    PROVIDER_DETAILS_FIELDS
  )
  const [profileEditMode, setProfileEditMode] = useState<boolean>(false)

  const [saveProgress, setSaveProgress] = useState<SaveProgress>(
    SaveProgress.NotStarted
  )
  const [editPhotoMode, setEditPhotoMode] = useState<boolean>(false)
  const [showPairModal, setShowPairModal] = useState<boolean>(false)

  useEffect(() => {
    getMyProviderDetails()
  }, [])

  const handlePair = () => {
    getPairingCode()
    setShowPairModal(true)
  }

  const handleUnpair = () => {
    unpairHeadset()
  }

  useEffect(() => {
    setShowPairModal(false)
  }, [isPaired])

  const onSubmit = async (values: FieldValues) => {
    try {
      const updatedProvider = {
        ...myProviderDetails,
        ...values,
        photoUrl: myProviderDetails?.originalPhotoUrl,
      }
      delete updatedProvider.originalPhotoUrl
      await updateProvider(updatedProvider)
      setProfileEditMode(false)
    } catch (err) {
      console.error(err)
    }
  }

  const addInputFields = (
    detailFields: Field[],
    options: Options,
    errors: any,
    control: Control<FieldValues, any>
  ) =>
    detailFields.map((detailField) => ({
      ...detailField,
      editComponent: getInputFieldBasedOnType({
        field: detailField,
        options,
        errors,
        control,
      }),
      viewComponent: getViewFieldBasedOnType({
        field: detailField,
        options,
        value: myProviderDetails
          ? (myProviderDetails as any)[detailField.backendKey || '']
          : null,
        existingValues: myProviderDetails,
      }),
    }))

  const saveFile = async (canvasEl: HTMLCanvasElement) => {
    const FILE_NAME = 'profile'
    const FILE_APPENSION = dateFormatters.imageLabel()
    const name = `${FILE_NAME}_${FILE_APPENSION}`

    canvasEl.toBlob(
      async (blob) => {
        if (blob) {
          const file = new File([blob], name)
          const results = await uploadFileToAWS({
            name,
            path: `profile-images/provider/${myProviderDetails?.providerId}`,
            file,
            setSaveProgress,
          })
          await updateProviderPhoto(results.key)
          setEditPhotoMode(false)
        }
      },
      'image/png',
      1
    )
  }

  useEffect(() => {
    if (myProviderDetails) {
      const formVersionOfDetails = profileDetailFields.map((section) => ({
        ...section,
        fields: addInputFields(section.fields, options, errors, control),
      }))
      setProfileDetailFields(formVersionOfDetails)
    }
  }, [myProviderDetails, options, errors])

  useEffect(() => {
    profileDetailFields.forEach((section: Section) => {
      section.fields.forEach((field: Field) => {
        setValue(
          field.backendKey,
          myProviderDetails
            ? (myProviderDetails as any)[field.backendKey]
            : field.initialValue
        )
      })
    })
  }, [myProviderDetails, options])

  return myProviderDetails ? (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ProfileView
        isHeadsetPaired={isPaired}
        providerDetailFields={profileDetailFields}
        user={myProviderDetails}
        saveProgress={saveProgress}
        setUserImage={saveFile}
        {...{
          editPhotoMode,
          setEditPhotoMode,
        }}
        {...{
          handlePair,
          handleUnpair,
          unpairError,
        }}
        {...{ showPairModal, setShowPairModal }}
        {...{ profileEditMode, setProfileEditMode }}
      />
    </form>
  ) : (
    <></>
  )
}

export { Profile }
