import 'plyr-react/plyr.css'

import { Box, Typography } from '@mui/material'
import { Dispatch, SetStateAction } from 'react'

import { Modal } from '../../../components'
import { useVrHeadset } from '../../../providers/VrHeadset/VrHeadset.provider'

interface PairHeadSetModalProps {
  showPairModal: boolean
  setShowPairModal: Dispatch<SetStateAction<boolean>>
}

export const PairHeadSetModal = ({
  showPairModal,
  setShowPairModal,
}: PairHeadSetModalProps) => {
  const { pairingCode, pairingInstructionVideo } = useVrHeadset()
  const instructions = [
    `On your Virtual Reality (VR) headset, press the <b>POWER</b> button to turn on the device.`,
    `Pick up your VR controllers and put on your headset.`,
    `In VR, select <b>APPS</b> from the main navigation tray.`,
    `Select the <b>NORTH-STAR CARE VR APP</b> in the list and click to open.`,
    `Enter the 6-digit verification code from the provider portal (to the left of these instructions) into the North-Star Care VR APP, then select <b>PAIR NOW</b>.`,
    `In the provider portal, close the Pair Headset Instructions window and confirm your headset now has a green checkmark and a new option to UNPAIR.`,
    `Once your headset is successfully paired, you can now seamlessly join VR meetings from your schedule in the provider portal.`,
  ]
  return (
    <Modal
      open={showPairModal}
      setOpen={setShowPairModal}
      title={'PAIR HEADSET INSTRUCTIONS'}
      width={'800px'}
      ModalContent={
        <Box sx={{ margin: '35px', height: '100%' }}>
          <Box sx={{ marginBottom: '25px' }}>
            <Typography sx={{ fontSize: '16px', lineHeight: '19px' }}>
              For instructions on how to pair your VR headset and North-Star
              Care account, watch the 2-minute video tutorial or see the written
              instruction steps below.
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'stretch',
              alignContent: 'stretch',
            }}
          >
            <Box
              sx={{
                minHeight: '100%',
                width: '45%',
                padding: '5px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Box>
                <Typography sx={{ fontSize: '14x', lineHeight: '18px' }}>
                  VIDEO TUTORIAL
                </Typography>
                <Box sx={{ marginTop: '20px' }}>
                  {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                  <video width={'100%'} controls className='plyr-react plyr'>
                    <source src={pairingInstructionVideo} type='video/mp4' />
                  </video>
                </Box>
              </Box>
              <Box>
                <Typography sx={{ fontSize: '14x', lineHeight: '18px' }}>
                  6 DIGIT VERIFICATION CODE
                </Typography>
                <Typography
                  sx={{
                    fontSize: '50px',
                    lineHeight: '60px',
                    fontWeight: 700,
                    letterSpacing: '15px',
                  }}
                >
                  {pairingCode}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ width: '55%', padding: '5px' }}>
              <Typography sx={{ fontSize: '14x', lineHeight: '18px' }}>
                INSTRUCTION STEPS
              </Typography>
              <ol
                style={{
                  fontWeight: '400',
                  fontSize: '16px',
                  lineHeight: '21px',
                }}
              >
                {instructions.map((instr, i) => (
                  <li key={'instruction-' + i}>
                    <Typography
                      sx={{ fontSize: '16px', lineHeight: '19px' }}
                      dangerouslySetInnerHTML={{ __html: instr }}
                    >
                      {/* {instr} */}
                    </Typography>
                  </li>
                ))}
                <br />
                <Typography sx={{ fontSize: '16px', lineHeight: '19px' }}>
                  For additional VR support, please call (253) 357-6413.
                </Typography>
              </ol>
            </Box>
          </Box>
        </Box>
      }
    />
  )
}
