import { Person } from '@mui/icons-material'
import { Box, CircularProgress, Typography } from '@mui/material'
import { useState } from 'react'

import { useProviderDetails } from '../../../providers/ProviderDetails/ProviderDetails.provider'
import { themeColors } from '../../../theme'

export const DetailRow = ({
  primaryLink,
  scheduleLinkObj,
  linkVisibleToPatient,
}: {
  scheduleLinkObj: {
    id: number
    meetingName: string
    meetingUrl: string
    duration: number
    isVisibleToPatient: boolean
  }
  primaryLink?: string
  linkVisibleToPatient: boolean
}) => {
  const { updateGReminderEventVisibility } = useProviderDetails()

  const [meetingUrlCopied, setMeetingUrlCopied] = useState<boolean>(false)

  const [spinner, setSpinner] = useState<boolean>(false)

  const handlePatientVisibilityChange = async (e: any) => {
    setSpinner(true)

    if (scheduleLinkObj) {
      await updateGReminderEventVisibility(scheduleLinkObj.id, e.target.checked)
      setSpinner(false)
    }
  }

  const copyMeetingUrl = (primaryLink: string) => {
    // const htmlContent = `<a href="${primaryLink}">${primaryLink}<a>`
    navigator.clipboard.writeText(`${primaryLink}`)
    setMeetingUrlCopied(true)
    setTimeout(() => {
      setMeetingUrlCopied(false)
    }, 1000)
  }

  return (
    <>
      <Box
        style={{
          opacity: spinner ? 0.5 : 1,
        }}
        key={scheduleLinkObj?.meetingUrl}
        sx={{
          width: '100%',
          marginBottom: 2,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          width={150}
          sx={{
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '14px',
            alignSelf: 'center',
          }}
        >
          {scheduleLinkObj?.meetingName}
        </Typography>

        <Box width={20}>
          {spinner ? (
            <CircularProgress style={{ width: '15px', height: '15px' }} />
          ) : null}
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
          }}
        >
          <Person fontSize='small' />
          <input
            type='checkbox'
            checked={linkVisibleToPatient}
            defaultChecked={linkVisibleToPatient}
            onClick={handlePatientVisibilityChange}
            disabled={spinner}
          />
        </Box>

        {!meetingUrlCopied ? (
          <Box
            onClick={() =>
              primaryLink
                ? copyMeetingUrl(primaryLink)
                : scheduleLinkObj?.meetingUrl &&
                  copyMeetingUrl(scheduleLinkObj?.meetingUrl)
            }
            sx={{
              fontWeight: 700,
              fontSize: '10px',
              lineHeight: '12px',
              color: themeColors.blue40,
              cursor: 'pointer',
              alignSelf: 'center',
            }}
          >
            COPY
          </Box>
        ) : (
          <Box
            sx={{
              fontWeight: 700,
              fontSize: '10px',
              lineHeight: '12px',
              color: themeColors.green,
            }}
          >
            COPIED!
          </Box>
        )}
      </Box>
    </>
  )
}
