import {
  Autocomplete,
  Box,
  Chip,
  Radio,
  TextField,
  Typography,
} from '@mui/material'
import { TimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { useContext, useEffect, useState } from 'react'
import { Control, Controller, FieldError, FieldValues } from 'react-hook-form'
import { getInfo } from 'react-mediainfo'
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import * as yup from 'yup'

import { ImageUpload, VerticalLine } from '../../components'
import { getFileType } from '../../components/ImageUpload/ImageUpload'
import { PeerGroupThumbnail } from '../../components/ImageUpload/PreviewImage'
import { Options } from '../../providers/Options/types'
import {
  PeerGroupsContext,
  PeerGroupsContextInterface,
} from '../../providers/PeerGroups/PeerGroups.provider'
import { PeerGroupMedia } from '../../providers/PeerGroups/types'
import { themeColors } from '../../theme'
import { BaseOption, Field, FieldType, Section, SectionFields } from './types'

export const getFieldValidationSchema = (fieldsArray: SectionFields) => {
  const yupObjShape: any = {}
  fieldsArray.forEach((section: Section) => {
    section.fields.forEach((field: Field) => {
      yupObjShape[field.backendKey] = field.validation
    })
  })

  return yup.object().shape(yupObjShape)
}

interface ErrorMessageProps {
  error?: FieldError
}

const ErrorMessage = ({ error }: ErrorMessageProps) => (
  <Box>
    {error ? (
      <Typography
        sx={{
          fontWeight: 400,
          fontSize: '12px',
          color: themeColors.red,
        }}
        color='error'
      >
        {error.message}
      </Typography>
    ) : null}
  </Box>
)

interface AutocompleteMultipleProps {
  field: Field
  options: any[]
  control: Control<FieldValues, any>
}

const AutocompleteMultiple = ({
  field,
  options,
  control,
}: AutocompleteMultipleProps) => {
  return (
    <Box>
      <Controller
        name={field.backendKey}
        control={control}
        rules={{
          validate: (data) => {
            if (data.length === 0) return false
          },
        }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Box>
            <Autocomplete
              multiple
              options={options}
              sx={{ width: 'auto', height: 'min-content' }}
              getOptionLabel={(option: any) => `${option.label}`}
              renderTags={(tagValue, getTagProps) => {
                return tagValue.map((val, index) => {
                  const option = options.find((opt) =>
                    field.chipLabelKey
                      ? opt[field.chipLabelKey] === val[field.chipLabelKey] ||
                        opt.apiId === val
                      : opt.apiId === val
                  )
                  return (
                    <Chip
                      {...getTagProps({ index })}
                      key={`${field.backendKey}-${index}`}
                      label={
                        option
                          ? field.chipLabelKey
                            ? (option as any)[field.chipLabelKey]
                            : option.label
                          : ''
                      }
                    />
                  )
                })
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id={field.backendKey}
                  name={field.backendKey}
                  size='small'
                  sx={{ height: 'min-content' }}
                  variant='outlined'
                  className={!field.isEditable ? 'Mui-disabled' : ''}
                  inputProps={{
                    ...params.inputProps,
                    readOnly: !field.isEditable,
                  }}
                  error={!!error}
                />
              )}
              value={value || []}
              defaultValue={value || []}
              onChange={(_, data) =>
                onChange(
                  [...data].map((d) =>
                    !field.chipLabelKey ? d.apiId || d.value || d : d
                  )
                )
              }
              isOptionEqualToValue={(opt, val) =>
                (!field.chipLabelKey ? opt.apiId || opt.value || opt : opt) ===
                val
              }
            />
            <ErrorMessage {...{ error, field }} />
          </Box>
        )}
      />
    </Box>
  )
}

interface AutocompleteFieldProps {
  field: Field
  options: any[]
  control: Control<FieldValues, any>
}

const AutocompleteField = ({
  field: textField,
  options,
  control,
}: AutocompleteFieldProps) => {
  return (
    <Box>
      <Controller
        control={control}
        name={textField.backendKey}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Box>
            <Autocomplete
              options={options}
              getOptionLabel={(option) =>
                `${
                  option.label ||
                  options.find((opt) =>
                    value ? (opt.apiId || opt) === value : opt.apiId || opt
                  )?.label
                }`
              }
              sx={{ width: 'auto' }}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  id={textField.backendKey}
                  name={textField.backendKey}
                  size='small'
                  variant='outlined'
                  className={!textField.isEditable ? 'Mui-disabled' : ''}
                  inputProps={{
                    ...params.inputProps,
                    readOnly: !textField.isEditable,
                  }}
                  error={!!error}
                />
              )}
              value={(() => {
                const selectedOption = options.find(
                  (opt) => (opt.apiId || opt) === value
                )
                return selectedOption?.apiId || selectedOption
              })()}
              defaultValue={value ? value : null}
              onChange={(_: any, data: any) => onChange(data?.apiId || data)}
              isOptionEqualToValue={(opt, val) => (opt?.apiId || opt) === val}
            />
            <ErrorMessage {...{ error, textField }} />
          </Box>
        )}
      />
    </Box>
  )
}

interface QuestionGroupProps {
  field: Field
  options: any[]
  control: Control<FieldValues, any>
}

const QuestionGroup = ({ field, options, control }: QuestionGroupProps) => {
  const fields: string[] = field.backendKey.split(',')

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
      }}
    >
      {fields.map((fieldKey: string, i: number) => (
        <Box
          key={fieldKey}
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            width: '100%',
            padding: '5px',
          }}
        >
          <Controller
            control={control}
            name={fieldKey}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Box>
                <Autocomplete
                  options={options.map((opt) => `${opt.label}`)}
                  sx={{ width: 'auto', paddingRight: '10px' }}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      id={fieldKey}
                      name={fieldKey}
                      size='small'
                      variant='outlined'
                      error={!!error}
                    />
                  )}
                  defaultValue={value}
                  onChange={(_: any, data: any) => onChange(data)}
                />
                <ErrorMessage {...{ error, field }} />
              </Box>
            )}
          />

          <Typography variant='caption'>{field.subLabels![i] ?? ''}</Typography>
        </Box>
      ))}
    </Box>
  )
}

interface InputFieldProps {
  field: Field
  control: Control<FieldValues, any>
  isMultiline?: boolean
}

const InputField = ({ field, control, isMultiline }: InputFieldProps) => (
  <Box>
    <Controller
      control={control}
      name={field.backendKey}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Box>
          <TextField
            size='small'
            variant='outlined'
            multiline={isMultiline}
            sx={{
              height: isMultiline ? '100%' : '35px',
            }}
            className={!field.isEditable ? 'Mui-disabled' : ''}
            inputProps={{ readOnly: !field.isEditable }}
            onChange={onChange}
            error={!!error}
            defaultValue={value || undefined}
          />
          <ErrorMessage {...{ error, field }} />
        </Box>
      )}
    />
  </Box>
)

interface RadioButtonFieldProps {
  field: Field
  options: any[]
  control: Control<FieldValues, any>
}

const RadioButtonField = ({
  field,
  options,
  control,
}: RadioButtonFieldProps) => (
  <Box>
    <Controller
      control={control}
      name={field.backendKey}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Box sx={{ display: 'flex' }}>
          {options.map((opt: BaseOption, i: number) => (
            <Box
              key={opt.label + i}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Radio
                sx={{
                  color: themeColors.blue,
                  '&.Mui-checked': {
                    color: themeColors.blue,
                  },
                }}
                checked={
                  value === undefined
                    ? opt.value === field.initialValue
                    : opt.value === value
                }
                onChange={(e) => {
                  onChange(e.target.value === 'true')
                }}
                value={`${opt.value}`}
                name={`${field.label}-radio`}
                inputProps={{ 'aria-label': opt.label }}
              />
              <Typography variant='caption'>{opt.label}</Typography>
            </Box>
          ))}
          <ErrorMessage {...{ error, field }} />
        </Box>
      )}
    />
  </Box>
)

interface DatePickerFieldProps {
  field: Field
  control: Control<FieldValues, any>
}

export const DatePickerField = ({ field, control }: DatePickerFieldProps) => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Box>
      <Controller
        name={field.backendKey}
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <Box>
            <DatePicker
              value={value || null}
              onChange={onChange}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  size='small'
                  variant='outlined'
                  sx={{ height: '35px' }}
                  error={!!error}
                />
              )}
            />
            <ErrorMessage {...{ error, field }} />
          </Box>
        )}
      />
    </Box>
  </LocalizationProvider>
)

interface TimePickerFieldProps {
  field: Field
  control: Control<FieldValues, any>
}

export const TimePickerField = ({ field, control }: TimePickerFieldProps) => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Box>
      <Box>
        <Controller
          name={field.backendKey}
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Box>
              <TimePicker
                value={value || null}
                onChange={onChange}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    size='small'
                    variant='outlined'
                    sx={{ height: '35px' }}
                    error={!!error}
                  />
                )}
              />
              <ErrorMessage {...{ error, field }} />
            </Box>
          )}
        />
      </Box>
    </Box>
  </LocalizationProvider>
)

interface NumberFieldProps {
  field: Field
  control: Control<FieldValues, any>
}

const NumberField = ({ field, control }: NumberFieldProps) => (
  <Box>
    <Controller
      control={control}
      name={field.backendKey}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Box>
          <TextField
            size='small'
            variant='outlined'
            sx={{ height: '35px' }}
            defaultValue={value || 0}
            className={!field.isEditable ? 'Mui-disabled' : ''}
            inputProps={{
              readOnly: !field.isEditable,
              type: 'number',
              inputMode: 'numeric',
              pattern: '[0-9]*',
              max: `${field.maxValue || 100}`,
              min: 1,
            }}
            onChange={onChange}
            error={!!error}
          />
          <ErrorMessage {...{ error, field }} />
        </Box>
      )}
    />
  </Box>
)

interface MediaFieldProps {
  field: Field
  control: Control<FieldValues, any>
  // acceptedFiles: File[] | undefined
  // setAcceptedFiles: React.Dispatch<React.SetStateAction<File[]>> | undefined
}

const MediaField = ({ field, control }: MediaFieldProps) => {
  const [errorMessage, setErrorMessage] = useState('')

  const { peerGroupAcceptedFiles, setPeerGroupAcceptedFiles } = useContext(
    PeerGroupsContext
  ) as PeerGroupsContextInterface

  const [acceptedFiles] = useState([])

  return (
    <>
      <Controller
        name={field.backendKey}
        control={control}
        defaultValue={[]}
        render={({ field: { value, onChange } }) => {
          return (
            <ImageUpload
              acceptedFiles={acceptedFiles}
              includeFileList
              files={value}
              errorMessage={errorMessage}
              handle360Change={(checked, file) => {
                onChange([
                  ...value.map((v: PeerGroupMedia) =>
                    file === v ? { ...v, is360: checked } : v
                  ),
                ])
              }}
              handleDelete={(file) => {
                onChange([
                  ...value.map((v: PeerGroupMedia) =>
                    file === v ? { ...v, markToDelete: true } : v
                  ),
                ])
              }}
              handleNewImage={async (acceptedImages) => {
                acceptedImages.forEach(async (file) => {
                  if (
                    peerGroupAcceptedFiles.some(
                      (acceptedFile) => acceptedFile.fileName === file.name
                    )
                  ) {
                    setErrorMessage('Cannot upload 2 files of the same name.')
                    return
                  }
                  if (file.type.includes('video/mp4')) {
                    console.log('fileType', file.type)
                    if (file.size > 800 * 1024 * 1024) {
                      setErrorMessage(
                        'Video exceeds the video size limit of 800MB'
                      )
                      return
                    }
                    const videoData = await getInfo(file)
                    if (Number(videoData.media.track[1].FrameRate) > 60) {
                      setErrorMessage('Framerate of video exceeds 60fps')
                      return
                    }
                    // @ts-ignore
                    else if (
                      Number(videoData.media.track[1].Sampled_Width) > 3840
                    ) {
                      setErrorMessage('Resolution of video exceeds 4k')
                      return
                    } else if (
                      Number(videoData.media.track[1].Sampled_Height) > 2160
                    ) {
                      setErrorMessage('Resolution of video exceeds 4k')
                      return
                    } else {
                      setPeerGroupAcceptedFiles &&
                        setPeerGroupAcceptedFiles((prevState) => [
                          ...prevState,
                          {
                            is360: false,
                            fileType: getFileType(file),
                            mimeType: file.type,
                            fileName: file.name,
                            file: file,
                            markToDelete: false,
                          },
                        ])
                      setErrorMessage('')
                    }
                  }
                  if (file.name.includes('.glb')) {
                    if (file.size > 20 * 1024 * 1024) {
                      setErrorMessage(
                        'GLB exceed the glb file size limit of 20MB'
                      )
                      return
                    } else {
                      const loader = new GLTFLoader()
                      let numMeshes = 0
                      let numVertices = 0
                      loader
                        .loadAsync(URL.createObjectURL(file))
                        .then((gltf: any) => {
                          gltf.scene.traverse((child: any) => {
                            if (child instanceof THREE.Mesh) {
                              numMeshes = numMeshes + 1
                            }
                            if (
                              child.geometry instanceof THREE.BufferGeometry
                            ) {
                              const position =
                                child.geometry.getAttribute('position')
                              if (position) {
                                numVertices += position.count
                              }
                            }
                          })
                        })
                        .then(() => {
                          if (numMeshes > 150) {
                            setErrorMessage('GLB mesh count exceeds 150')
                            return
                          } else if (numVertices > 14500) {
                            setErrorMessage('GLB vertices count exceeds 14500')
                            return
                          } else {
                            setPeerGroupAcceptedFiles &&
                              setPeerGroupAcceptedFiles((prevState) => [
                                ...prevState,
                                {
                                  is360: false,
                                  fileType: getFileType(file),
                                  mimeType: file.type,
                                  fileName: file.name,
                                  file: file,
                                  markToDelete: false,
                                },
                              ])
                            setErrorMessage('')
                          }
                        })
                    }
                  }
                  if (file.type.includes('image')) {
                    if (file.size > 10 * 1024 * 1024) {
                      setErrorMessage(
                        'Image exceeds the image size limit of 10MB'
                      )
                      return
                    } else {
                      setPeerGroupAcceptedFiles &&
                        setPeerGroupAcceptedFiles((prevState) => [
                          ...prevState,
                          {
                            is360: false,
                            fileType: getFileType(file),
                            mimeType: file.type,
                            fileName: file.name,
                            file: file,
                            markToDelete: false,
                          },
                        ])
                      setErrorMessage('')
                    }
                  }
                  if (file.type.includes('audio')) {
                    if (file.size > 45 * 1024 * 1024) {
                      setErrorMessage(
                        'Audio file exceeds the audio file size limit of 45MB'
                      )
                      return
                    } else {
                      setPeerGroupAcceptedFiles &&
                        setPeerGroupAcceptedFiles((prevState) => [
                          ...prevState,
                          {
                            is360: false,
                            fileType: getFileType(file),
                            mimeType: file.type,
                            fileName: file.name,
                            file: file,
                            markToDelete: false,
                          },
                        ])
                      setErrorMessage('')
                    }
                  }

                  if (file.type.includes('application/pdf')) {
                    if (file.size > 10 * 1024 * 1024) {
                      setErrorMessage(
                        'PDF file exceeds the PDF size limit of 10MB'
                      )
                      return
                    } else {
                      setPeerGroupAcceptedFiles &&
                        setPeerGroupAcceptedFiles((prevState) => [
                          ...prevState,
                          {
                            is360: false,
                            fileType: getFileType(file),
                            mimeType: file.type,
                            fileName: file.name,
                            file: file,
                            markToDelete: false,
                          },
                        ])
                      setErrorMessage('')
                    }
                  }

                  const allowedTypes = [
                    'image/jpeg',
                    'image/png',
                    'image/jpg',
                    'video/mp4',
                    'application/pdf',
                  ]
                  if (
                    !file.name.includes('.glb') &&
                    !allowedTypes.includes(file.type)
                  ) {
                    setErrorMessage(
                      'Only files of type .jpeg, .jpg, .png, .mp4, .pdf, or .glb are supported'
                    )
                    return
                  }
                })
                onChange([
                  ...[...value].map(
                    (file): PeerGroupMedia => ({
                      fileName: file.name,
                      fileType: getFileType(file),
                      mimeType: file.type,
                      is360: false,
                      file: file,
                      markToDelete: false,
                    })
                  ),
                ])
              }}
            />
          )
        }}
      />
    </>
  )
}

interface ImageSelectorProps {
  options: any[]
  field: Field
  control: Control<FieldValues, any>
  watchedFields?: { name: string; value: any }[] | null
}

const ImageSelector = ({
  field,
  options,
  control,
  watchedFields,
}: ImageSelectorProps) => (
  <Controller
    name={field.backendKey}
    control={control}
    render={({ field: { value, onChange } }) => (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          minHeight: '290px',
          width: '100%',
          minWidth: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            minHeight: '290px',
            display: 'flex',
            flexGrow: 2,
            overflowX: 'auto',
            scrollbarGutter: 'stable both-edges',
            marginRight: '30px',
          }}
        >
          {options.map((opt) => (
            <Box
              sx={{
                margin: '0 15px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
              key={opt.apiId}
            >
              <Radio
                defaultValue={
                  options.find((opt) => opt.apiId === value)?.apiId ||
                  options[0].apiId
                }
                size='medium'
                sx={{
                  color: themeColors.blue,
                  '&.Mui-checked': {
                    color: themeColors.blue,
                  },
                }}
                checked={
                  value === opt.value ||
                  (options.find((opt) => opt.apiId === value)?.apiId ||
                    options[0].apiId) === opt.apiId
                }
                onChange={(e) => {
                  onChange(opt.apiId)
                }}
                value={opt.apiId}
                name={`${field.label}-radio`}
                inputProps={{ 'aria-label': opt.apiId }}
              />
              <PeerGroupThumbnail imageSrc={opt.storageKey} />
              <Typography sx={{ fontWeight: 400, fontSize: '14px' }}>
                {opt.label}
              </Typography>
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            minHeight: '280px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            position: 'relative',
            marginLeft: '10px',
            minWidth: '150px',
          }}
        >
          <VerticalLine left={-20} />
          <Typography sx={{ fontSize: '14px', padding: '10px 0' }}>
            Preview
          </Typography>
          <PeerGroupThumbnail
            imageSrc={
              options.find((opt) => opt.apiId === value)?.storageKey ||
              options[0].storageKey
            }
            isPreviewMode={true}
            title={
              watchedFields?.find((watched) => watched.name === field.observe)
                ?.value || undefined
            }
          />
        </Box>
      </Box>
    )}
  />
)

export type GetInputFieldBasedOnTypeProps = {
  field: Field
  options: Options
  errors: any
  control: Control<FieldValues, any>
  watchedFields?: { name: string; value: any }[] | null
  // acceptedFiles?: File[]
  // setAcceptedFiles?: React.Dispatch<React.SetStateAction<File[]>>
  peerGroupAcceptedFiles?: PeerGroupMedia[]
  setPeerGroupAcceptedFiles?: React.Dispatch<
    React.SetStateAction<PeerGroupMedia[]>
  >
}

export const getInputFieldBasedOnType = ({
  field,
  options,
  errors,
  control,
  watchedFields,
  peerGroupAcceptedFiles,
  setPeerGroupAcceptedFiles,
}: // acceptedFiles,
// setAcceptedFiles,
GetInputFieldBasedOnTypeProps): JSX.Element => {
  switch (field.fieldType) {
    case FieldType.Autocomplete:
      return (
        <AutocompleteField
          options={
            field.optionsSelector ? (options as any)[field.optionsSelector] : []
          }
          {...{ field, control }}
        />
      )
    case FieldType.Multiselect:
      return (
        <AutocompleteMultiple
          options={
            field.optionsSelector ? (options as any)[field.optionsSelector] : []
          }
          {...{ field, control }}
        />
      )
    case FieldType.TextArea:
      return <InputField {...{ field, control }} isMultiline={true} />
    case FieldType.Radio:
      return (
        <RadioButtonField
          options={field.options || []}
          {...{ field, control }}
        />
      )
    case FieldType.Date:
      return <DatePickerField {...{ field, control }} />
    case FieldType.Time:
      return <TimePickerField {...{ field, control }} />
    case FieldType.Number:
      return <NumberField {...{ field, control }} />
    case FieldType.Media:
      return (
        <MediaField
          {...{
            field,
            control,
            peerGroupAcceptedFiles,
            setPeerGroupAcceptedFiles,
          }}
        />
      )
    case FieldType.ImageSelector:
      return (
        <ImageSelector
          options={
            field.optionsSelector ? (options as any)[field.optionsSelector] : []
          }
          {...{ control, field, errors, watchedFields }}
        />
      )
    case FieldType.QuestionGroup:
      return (
        <QuestionGroup
          options={field.options || []}
          {...{ control, field, errors }}
        />
      )
    default:
      return <InputField {...{ field, control }} isMultiline={false} />
  }
}
