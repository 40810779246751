import { Box, Button, Container, Paper, Typography } from '@mui/material'
import { ReactNode, useEffect, useState } from 'react'

import { Header, Modal } from '../../components'
import { useNavigation } from '../../providers/Navigation/Navigation.provider'
import { ProviderUser } from '../../providers/ProviderDetails/types'
import { themeColors } from '../../theme'
import { useStyles } from '../Main/Main.styles'

export const MainView = ({
  usePaper = true,
  myProviderDetails,
  updateProviderOnlineStatus,
  children,
  getProviderOnlineStatus,
}: {
  usePaper?: boolean
  myProviderDetails: ProviderUser | null
  updateProviderOnlineStatus: (isOnline: boolean) => Promise<ProviderUser>
  children?: ReactNode
  getProviderOnlineStatus: () => Promise<any>
}) => {
  const { classes } = useStyles()
  const { navigation } = useNavigation()

  const [showCancelModalMode, setShowCancelModalMode] = useState(false)

  const getFirstOnlineStatus = async () => {
    const status = await getProviderOnlineStatus()
    setShowCancelModalMode(!status)
  }

  useEffect(() => {
    getFirstOnlineStatus()
  }, [])

  return (
    <div data-testid='main'>
      <Header pages={navigation!.navigationLinks} user={myProviderDetails} />
      {myProviderDetails?.accessRoles &&
      myProviderDetails?.accessRoles[0].description === 'Deactivated' ? (
        <h1 style={{ margin: '20vh', textAlign: 'center' }}>
          Your account has been suspended. Please contact your administrator if
          you believe this to be a mistake.
        </h1>
      ) : (
        <>
          <Container className={classes.mainView}>
            {usePaper ? (
              <Paper sx={{ padding: 5, height: '100%', overflow: 'auto' }}>
                <div className={classes.viewWrapper}>
                  <>{children}</>
                </div>
              </Paper>
            ) : (
              <div className={classes.viewWrapper}>
                <>{children}</>
              </div>
            )}
            {
              <Modal
                open={showCancelModalMode}
                setOpen={setShowCancelModalMode}
                title={'Set yourself to active?'}
                width={'30%'}
                ModalContent={
                  <Box sx={{ width: '92%', margin: 'auto' }}>
                    <Typography
                      sx={{
                        fontSize: '16px',
                        lineHeight: '19px',
                        color: themeColors.grayblack,
                      }}
                    >
                      You are currently set to away, but it looks like you are
                      back. Want to update your availability?
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        margin: '15px 0',
                        alignItems: 'center',
                      }}
                    >
                      <Button
                        variant='contained'
                        onClick={() => setShowCancelModalMode(false)}
                        sx={{ marginRight: '10px' }}
                      >
                        No Thanks
                      </Button>
                      <Button
                        variant='contained'
                        color='error'
                        onClick={async () => {
                          await updateProviderOnlineStatus(true)
                          setShowCancelModalMode(false)
                        }}
                        sx={{ marginRight: '10px' }}
                      >
                        Set to Active
                      </Button>
                    </Box>
                  </Box>
                }
              />
            }
          </Container>
        </>
      )}
    </div>
  )
}
