import { Visibility } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'

import { VerticalLine } from '../../../components'
import { SaveProgress } from '../types'
import { EditImage } from './EditImage.partial'
import { ViewImage } from './VIewImage.partial'

interface VisibleProfileProps {
  editPhotoMode: boolean
  setUserImage: (canvasEl: HTMLCanvasElement) => void
  user: any
  handleEditImageModeToggle: (onOrOff: 'on' | 'off') => void
  saveProgress: SaveProgress
  setEditPhotoMode: React.Dispatch<React.SetStateAction<boolean>>
}

export const VisibleProfile = ({
  editPhotoMode,
  setUserImage,
  user,
  handleEditImageModeToggle,
  saveProgress,
  setEditPhotoMode,
}: VisibleProfileProps) => (
  <Box
    sx={{
      position: 'relative',
      minHeight: '100%',
    }}
  >
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography
        sx={{
          fontWeight: 700,
          fontSize: 24,
          lineHeight: '125%',
          textAlign: 'center',
        }}
      >
        Provider Profile
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: 'auto',
        }}
      >
        <Visibility fontSize='small' sx={{ marginRight: '10px' }} />
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: 16,
            lineHeight: '125%',
          }}
        >
          Visible to Patients
        </Typography>
      </Box>
    </Box>
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '20px auto',
      }}
    >
      {editPhotoMode ? (
        <EditImage
          setUserImage={setUserImage}
          image={user.photoUrl}
          handleEditModeToggle={handleEditImageModeToggle}
          saveProgress={saveProgress}
        />
      ) : (
        <ViewImage user={user} setEditMode={setEditPhotoMode} />
      )}
    </Box>
    <VerticalLine right={-25} />
  </Box>
)
