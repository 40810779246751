import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { themeColors } from '../../theme'

export const useStyles = makeStyles()((theme: Theme) => ({
  outerUl: {
    paddingLeft: '1rem',
  },
  innerUl: {
    paddingLeft: '2rem',
  },
  errorContainer: {
    position: 'relative',
    paddingBottom: '1.5rem',
  },
  error: {
    color: themeColors.red,
    position: 'absolute',
    bottom: 0,
  },
}))
