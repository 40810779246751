import { Box, Button } from '@mui/material'
import { Dispatch, SetStateAction, useState } from 'react'

import { Modal } from '../../../../components'
import { useOptions } from '../../../../providers/Options/Options.provider'
import { usePeerGroups } from '../../../../providers/PeerGroups/PeerGroups.provider'
import { useSchedule } from '../../../../providers/Schedule/Schedule.provider'
import { themeColors } from '../../../../theme'

interface AddPatientToGroupModalProps {
  showAddPatientModal: boolean
  setShowAddPatientModal: Dispatch<SetStateAction<boolean>>
  peerGroupId: number | undefined
  peerGroupSessionId: number
  setAddingPatient: Dispatch<SetStateAction<boolean>>
  activeDay: Date | null
}

export const AddPatientToGroupModal = ({
  showAddPatientModal,
  setShowAddPatientModal,
  peerGroupId,
  peerGroupSessionId,
  setAddingPatient,
  activeDay,
}: AddPatientToGroupModalProps) => {
  const { options, optionsLoading } = useOptions()

  const { addPatientToPeerGroup, addPatientToSinglePeerGroupSession } =
    usePeerGroups()

  const { get7dayAppointments } = useSchedule()

  const [selectedPatientId, setSelectedPatientId] = useState<string>('')

  const handleAddToSession = async () => {
    setAddingPatient(true)
    await addPatientToSinglePeerGroupSession(
      Number(selectedPatientId),
      peerGroupSessionId
    )
    setShowAddPatientModal(false)
    await get7dayAppointments(activeDay!)
    setAddingPatient(false)
  }

  const handleAddToSeries = async () => {
    setAddingPatient(true)
    if (peerGroupId) {
      await addPatientToPeerGroup(Number(selectedPatientId), peerGroupId)
    }
    setShowAddPatientModal(false)
    await get7dayAppointments(activeDay!)
    setAddingPatient(false)
  }

  return (
    <div>
      <Modal
        open={showAddPatientModal}
        setOpen={setShowAddPatientModal}
        title={'Add Patient to Peer Group'}
        width={'30%'}
        ModalContent={
          <Box
            sx={{ margin: '20px', display: 'flex', flexDirection: 'column' }}
          >
            <select
              value={selectedPatientId}
              onChange={(e) => setSelectedPatientId(e.target.value)}
              defaultValue={''}
            >
              <option disabled value={''}>
                Select a Patient
              </option>
              {!optionsLoading &&
                options?.patients.map((patient) => (
                  <option key={patient.patientId} value={patient.patientId}>
                    {patient.preferredName}
                  </option>
                ))}
            </select>
            <Box
              sx={{
                margin: '30px 0px 0px 0px',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Button
                variant='contained'
                sx={{
                  color: themeColors.white,
                  fontSize: '14px',
                  margin: '0px 10px 0px 5px',
                  width: '500px',
                }}
                onClick={handleAddToSession}
              >
                Add to just this session
              </Button>
              <Button
                variant='contained'
                sx={{
                  color: themeColors.white,
                  fontSize: '14px',
                  margin: '0px 5px 0px 10px',
                  width: '500px',
                }}
                onClick={handleAddToSeries}
              >
                Add to series
              </Button>
            </Box>
          </Box>
        }
      />
    </div>
  )
}
