import { createContext, ReactNode, useContext, useState } from 'react'

import { useAxios } from '../../utilities/Requests/useAxios'
import { PatientCareTeamContextType, patientCareTeamObj } from './types'

export const PatientCareTeamContext =
  createContext<PatientCareTeamContextType | null>(null)

export const PatientCareTeamProvider = ({
  children,
}: {
  children?: ReactNode
}) => {
  const { fetch } = useAxios()

  const [patientCareTeam, setPatientCareTeam] = useState<patientCareTeamObj[]>(
    []
  )

  const [providerContactInfo, setProviderContactInfo] = useState<{
    email: string
    companyPhone: string
    personalPhone: string
    timeZone: string
  }>({ email: '', companyPhone: '', personalPhone: '', timeZone: '' })

  const getPatientCareTeam = async (patientId: number) => {
    const { data, error } = await fetch({
      path: `Provider/GetPatientCareTeam?patientId=${patientId}`,
    })
    if (data) {
      setPatientCareTeam(data)
      return data
    } else if (error) {
      throw new Error(`Error in getPatientCareTeam.`)
    }
  }

  // for selected provider popover
  const getProviderContactInfo = async (providerId: number) => {
    const { data, error } = await fetch({
      path: `Provider/GetProviderDetails?providerId=${providerId}`,
    })

    if (error) {
      throw new Error(`Error in getting provider contact info.`)
    }

    setProviderContactInfo({
      email: data?.emailAddress,
      companyPhone: data?.companyPhone,
      personalPhone: data?.personalPhone,
      timeZone: data?.timeZone,
    })
  }

  return (
    <PatientCareTeamContext.Provider
      value={{
        patientCareTeam,
        getPatientCareTeam,
        providerContactInfo,
        getProviderContactInfo,
      }}
    >
      {children}
    </PatientCareTeamContext.Provider>
  )
}

export const usePatientCareTeam = () =>
  useContext(PatientCareTeamContext) as PatientCareTeamContextType
