import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
export const useStyles = makeStyles()((theme: Theme) => ({
  paper: {
    overflowX: 'unset',
    overflowY: 'unset',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: -10,
      right: '50%',
      width: 10,
      height: 10,
      backgroundColor: theme.palette.background.paper,
      transform: 'translate(-50%, 50%) rotate(315deg)',
      clipPath:
        'polygon(-5px -5px, calc(100% + 5px) -5px, calc(100% + 5px) calc(100% + 5px))',
    },
  },
}))
