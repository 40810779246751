import { Typography } from '@mui/material'
import { useEffect, useState } from 'react'

import InvalidSvg from '../../assets/icon.invalid.svg'
import ValidSvg from '../../assets/icon.valid.svg'
import ProgressBar from '../ProgressBar/ProgressBar'
import RevealPassword from '../RevealPassword/RevealPassword'
import { useStyles } from './CreatePassword.styles'

type PasswordErrors = {
  length?: boolean
  upper?: boolean
  number?: boolean
  special?: boolean
}

type CreatePasswordProps = {
  setPassword: (password: string) => void
  setIsDisabled: (isDisabled: boolean) => void
  clearErrors: () => void
}

const CreatePassword = ({
  setPassword,
  setIsDisabled,
  clearErrors,
}: CreatePasswordProps) => {
  const { classes } = useStyles()
  const initialErrors = {
    length: true,
    upper: true,
    number: true,
    special: true,
  }

  const PasswordStrength = ['High', 'Medium', 'Medium', 'Low', 'Low']

  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [passwordErrors, setPasswordErrors] = useState<PasswordErrors>({
    ...initialErrors,
  })
  const [strength, setStrength] = useState(PasswordStrength[0])

  useEffect(() => {
    setIsDisabled(true)
  }, [])

  useEffect(() => {
    clearErrors()
    const newErrors: PasswordErrors = {}

    if (newPassword.length < 12) {
      newErrors.length = true
    }

    if (newPassword.search(/[A-Z]/) === -1) {
      newErrors.upper = true
    }

    if (newPassword.search(/[0-9]/) === -1) {
      newErrors.number = true
    }

    if (newPassword.search(/[!@#$%^&*()+=?<>,.[\]{}/\\~-]/) === -1) {
      newErrors.special = true
    }

    const errorCount = Object.keys(newErrors).length
    setIsDisabled(errorCount !== 0 || newPassword !== confirmPassword)
    setPassword(newPassword)
    setPasswordErrors(newErrors)
    setStrength(PasswordStrength[errorCount])
  }, [newPassword, confirmPassword])

  const renderIcon = (idx: keyof PasswordErrors) => {
    if (!newPassword && !confirmPassword) {
      return null
    }

    return passwordErrors[idx] ? (
      <img src={InvalidSvg} alt='invalid' />
    ) : (
      <img src={ValidSvg} alt='valid' />
    )
  }

  const renderStatusBar = () => {
    const errorCount = Object.keys(passwordErrors).length
    const possibleErrors = PasswordStrength.length
    const percent = !newPassword.length
      ? 0
      : Math.floor(
          ((possibleErrors - errorCount) / PasswordStrength.length) * 100
        )

    return <ProgressBar percent={percent} />
  }

  const renderPasswordError = () => {
    if (!newPassword || !confirmPassword || newPassword === confirmPassword) {
      return null
    }

    return <div className={classes.error}>Passwords must match</div>
  }

  return (
    <div>
      <Typography variant='subtitle2'>Enter Password:</Typography>
      <RevealPassword
        id='password'
        setValue={(val) => setNewPassword(val)}
        value={newPassword}
      />
      {renderStatusBar()}
      <Typography variant='subtitle2'>Password strength: {strength}</Typography>
      <ul className={classes.outerUl}>
        <li>Must be at least 12 characters {renderIcon('length')}</li>
        <li>
          Must include at least one
          <ul className={classes.innerUl}>
            <li>Uppercase Letter {renderIcon('upper')}</li>
            <li>Number {renderIcon('number')}</li>
            <li>
              Special Character (i.e. !, #, $, %, etc.)&nbsp;
              {renderIcon('special')}
            </li>
          </ul>
        </li>
      </ul>
      <Typography variant='subtitle2'>Confirm Password:</Typography>
      <div className={classes.errorContainer}>
        <RevealPassword
          id='confirmPassword'
          setValue={(val) => setConfirmPassword(val)}
          value={confirmPassword}
        />
        {renderPasswordError()}
      </div>
    </div>
  )
}

export default CreatePassword
