// @ts-nocheck

import { yupResolver } from '@hookform/resolvers/yup'
import { Box, CircularProgress } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { Control, FieldValues, useForm } from 'react-hook-form'

import { Phase } from '../../../components/Progress/ProgressWithLabel'
import { useOptions } from '../../../providers/Options/Options.provider'
import { Options } from '../../../providers/Options/types'
import {
  PeerGroupsContext,
  PeerGroupsContextInterface,
  usePeerGroups,
} from '../../../providers/PeerGroups/PeerGroups.provider'
import {
  PeerGroupAppointment,
  PeerGroupMedia,
} from '../../../providers/PeerGroups/types'
import {
  getFieldValidationSchema,
  getInputFieldBasedOnType,
} from '../../../utilities/Forms/SectionFields'
import { Field, Section, SectionFields } from '../../../utilities/Forms/types'
import { getViewFieldBasedOnType } from '../../../utilities/Forms/ViewFields'
import { PeerGroupMapper } from '../../../utilities/Requests/apiMappers'
import { uploadFileToAWS } from '../../../utilities/Storage'
import { PEER_GROUP_FIELDS } from './PeerGroupFields'
import { PeerGroupsView } from './PeerGroups.view'
import { ViewType } from './types'

const PeerGroups = () => {
  const {
    peerGroups,
    peerGroupDetails,
    getPeerGroupList,
    getPeerGroupDetails,
    addPeerGroup,
    updatePeerGroup,
    getPeerGroupSchedule,
    joinPeerGroupSession,
    deletePeerGroup,
  } = usePeerGroups()

  const [viewMode, setViewMode] = useState<ViewType>(ViewType.List)
  const [peerGroupId, setPeerGroupId] = useState<number | null>(null)
  const [peerGroupDetailFields, setPeerGroupDetailFields] =
    useState<SectionFields>(PEER_GROUP_FIELDS)
  const [peerGroupDetailsEditMode, setPeerGroupDetailsEditMode] =
    useState<boolean>(true)
  const [imageValue, setImageValue] = useState<number>(0)
  const [activePhase, setActivePhase] = useState<Phase>({
    label: 'Filling form',
    total: 1,
    value: 0,
  })
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [showJoinModal, setShowJoinModal] = useState<boolean>(false)
  const [showCancelModalMode, setShowCancelModalMode] = useState<boolean>(false)
  const [showAddSessionMediaModal, setShowAddSessionMediaModal] =
    useState<boolean>(false)

  const { options, optionsLoading, reloadOptions } = useOptions()

  const { peerGroupAcceptedFiles, setPeerGroupAcceptedFiles } = useContext(
    PeerGroupsContext
  ) as PeerGroupsContextInterface

  // const [acceptedFiles, setAcceptedFiles] = useState<File[]>([])

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
    reset,
  } = useForm({
    reValidateMode: 'onChange',
    resolver: yupResolver(getFieldValidationSchema(PEER_GROUP_FIELDS)),
  })

  const watchedFieldsList = [
    ...PEER_GROUP_FIELDS.map((section: Section) =>
      section.fields
        .flat()
        .filter((x: Field) => x.watch)
        .map((x: Field) => x.backendKey)
    ).flat(),
  ]

  const watchedFields: { name: string; value: any }[] = watchedFieldsList.map(
    (wf) => ({
      name: wf,
      value: watch(wf),
    })
  )

  const handleRowClick = (peerGroupId: number) => {
    setPeerGroupId(peerGroupId)
    setViewMode(ViewType.Details)
  }

  const goToNewPeerGroup = () => {
    setPeerGroupId(null)
    setViewMode(ViewType.Add)
  }

  useEffect(() => {
    if (peerGroupId) {
      getPeerGroupDetails(peerGroupId)
      setPeerGroupDetailsEditMode(true)
    } else {
      getPeerGroupDetails()
      setPeerGroupDetailsEditMode(false)
    }
  }, [peerGroupId])

  useEffect(() => {
    if (peerGroupDetails && peerGroupDetails.media) {
      setPeerGroupAcceptedFiles([...peerGroupDetails.media])
    } else {
      setPeerGroupAcceptedFiles([])
    }
  }, [peerGroupDetails])

  useEffect(() => {
    reloadOptions()
    setPeerGroupDetailsEditMode(
      viewMode === ViewType.Add || viewMode === ViewType.Edit
    )
  }, [viewMode])

  useEffect(() => {
    setActivePhase({ ...activePhase, value: imageValue })
  }, [imageValue])

  const resetPeerGroupForm = () => {
    setPeerGroupId(null)
    setActivePhase({ label: 'Filling form' })
    reset()
  }

  const [timeZone, setTimeZone] = useState('')

  useEffect(() => {
    function getTimeZone() {
      try {
        const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
        setTimeZone(tz)
      } catch (error) {
        setTimeZone('UTC')
      }
    }
    getTimeZone()
  }, [])

  const onSubmit = async (values: FieldValues) => {
    setSubmitting(true)

    // Upload all new media first
    if (peerGroupAcceptedFiles && peerGroupAcceptedFiles.length) {
      const mediaList: PeerGroupMedia[] = [...peerGroupAcceptedFiles].filter(
        (m) => !m.markToDelete
      )

      const toUploadFiles: PeerGroupMedia[] = mediaList.filter((m) => m.file)

      if (toUploadFiles.length) {
        setActivePhase({
          label: 'Uploading new media',
          total: toUploadFiles.length,
        })
      }

      let imgCount = 0
      for (let i = 0; i < peerGroupAcceptedFiles.length; i++) {
        const media = { ...peerGroupAcceptedFiles[i] }
        if (media.file) {
          imgCount++
          setImageValue(imgCount)
          const results = await uploadFileToAWS({
            name: media.file!.name,
            path: `peer-group-attachments/${values.groupName}`,
            file: media.file!,
            bucket: process.env.REACT_APP_MEDIA_BUCKET,
          })

          delete media.file
          media.fileKey = results.key
          mediaList[i] = media
        }
        setImageValue(0)
        values.media = mediaList
      }
    }

    if (values.frequency === 3) {
      const calculateDayOfWeekOccurrence = (startDate: Date): number => {
        const firstDayOfMonth = new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          1
        )
        let occurrence = 1

        for (
          let currentDay = new Date(firstDayOfMonth);
          currentDay.getMonth() === startDate.getMonth();
          currentDay.setDate(currentDay.getDate() + 1)
        ) {
          if (currentDay.getDay() === startDate.getDay()) {
            if (currentDay.getDate() === startDate.getDate()) {
              break
            }
            occurrence++
          }
        }

        return occurrence
      }

      values.monthlyNths = calculateDayOfWeekOccurrence(values.startDate)
    }
    values.monthlyDayOfWeek = values.startDate.getDay()

    values.timezone = timeZone

    setPeerGroupAcceptedFiles([])

    try {
      setActivePhase({
        label: 'Saving',
      })
      const updatedPeerGroup = PeerGroupMapper.ToAPI(
        values,
        peerGroupDetails,
        options.peerGroupBackgrounds
      )
      if (viewMode === ViewType.Add) {
        await addPeerGroup(updatedPeerGroup)
        setViewMode(ViewType.List)
        resetPeerGroupForm()
      } else if (viewMode === ViewType.Edit) {
        await updatePeerGroup(updatedPeerGroup)
        setViewMode(ViewType.Details)
      }
    } catch (err) {
      console.error(err)
    } finally {
      setSubmitting(false)
    }
  }

  // SET INITIAL VALUES
  const addInputFields = (
    detailFields: Field[],
    options: Options,
    errors: any,
    control: Control<FieldValues, any>,
    watchedFields: { name: string; value: any }[] | null
  ) =>
    detailFields.map((detailField) => ({
      ...detailField,
      editComponent: getInputFieldBasedOnType({
        watchedFields,
        field: detailField,
        options,
        errors,
        control,
        peerGroupAcceptedFiles,
        setPeerGroupAcceptedFiles,
      }),
      viewComponent: getViewFieldBasedOnType({
        field: detailField,
        options,
        value: peerGroupDetails
          ? (peerGroupDetails as any)[
              detailField.renderKey || detailField.backendKey || ''
            ]
          : null,
        existingValues: peerGroupDetails,
      }),
    }))

  useEffect(() => {
    if (
      ((viewMode === ViewType.Details || viewMode === ViewType.Edit) &&
        peerGroupDetails) ||
      ViewType.Add
    ) {
      const formVersionOfDetails = peerGroupDetailFields.map((section, i) => ({
        ...section,
        sectionLabel: i
          ? section.sectionLabel
          : `${
              viewMode === ViewType.Edit
                ? 'Update Peer Group'
                : viewMode === ViewType.Add
                ? 'New Peer Group'
                : ''
            } `,
        fields: addInputFields(
          section.fields,
          options,
          errors,
          control,
          watchedFields
        ),
      }))
      setPeerGroupDetailFields(formVersionOfDetails)
    }
  }, [peerGroupDetails, options, viewMode, peerGroupAcceptedFiles])

  useEffect(() => {
    peerGroupDetailFields.forEach((section: Section) => {
      section.fields.forEach((field: Field) => {
        const multipartField = field.backendKey.split(',')
        if (multipartField.length > 1) {
          multipartField.forEach((mpf, i) => {
            setValue(
              mpf,
              peerGroupDetails
                ? (peerGroupDetails as any)[field.backendKey][i]
                : field.initialValue[i]
            )
          })
        } else {
          setValue(
            field.backendKey,
            peerGroupDetails
              ? (peerGroupDetails as any)[field.backendKey]
              : field.initialValue
          )
        }
      })
    })
  }, [peerGroupDetails, options, viewMode])

  const handleJoinSessionModal = async () => {
    await getPeerGroupSchedule(new Date())
    setShowJoinModal(true)
  }

  const handleJoinSession = async (
    peerGroupSession: PeerGroupAppointment
  ): Promise<boolean> => {
    return await joinPeerGroupSession(
      peerGroupSession.peerGroupSessionId,
      peerGroupSession.startTime
    )
  }

  const handleCancelPeerGroup = async () => {
    if (peerGroupId) {
      await deletePeerGroup(peerGroupId)
      setShowCancelModalMode(false)
      setPeerGroupId(null)
      setViewMode(ViewType.List)
    }
  }

  return peerGroups && !optionsLoading ? (
    <PeerGroupsView
      {...{
        viewMode,
        submitting,
        setViewMode,
        setPeerGroupId,
        peerGroupId,
        peerGroups,
        peerGroupDetails,
        getPeerGroupList,
        getPeerGroupDetails,
        addPeerGroup,
        updatePeerGroup,
        watchedFields,
        reset,
        showJoinModal,
        setShowJoinModal,
        showAddSessionMediaModal,
        setShowAddSessionMediaModal,
        showCancelModalMode,
        setShowCancelModalMode,
      }}
      {...{ peerGroupDetailsEditMode, setPeerGroupDetailsEditMode }}
      peerGroupDetailFields={peerGroupDetailFields}
      handleRowClick={handleRowClick}
      goToNewPeerGroup={goToNewPeerGroup}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      activePhase={activePhase}
      handleJoinSessionModal={handleJoinSessionModal}
      handleJoinSession={handleJoinSession}
      handleCancelPeerGroup={handleCancelPeerGroup}
    />
  ) : (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
      }}
    >
      <CircularProgress />{' '}
    </Box>
  )
}

export { PeerGroups }
