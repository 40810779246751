import './index.css'

import { StyledEngineProvider, ThemeProvider } from '@mui/material'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

import { App } from './App'
import { NavigationProvider } from './providers/Navigation/Navigation.provider'
import { theme } from './theme'

ReactDOM.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <NavigationProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </NavigationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
