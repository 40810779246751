import { Box } from '@mui/material'
import { Dispatch, SetStateAction, useEffect } from 'react'
import { FieldValues, UseFormHandleSubmit, UseFormReset } from 'react-hook-form'

import { NavigationLink } from '../../../components'
import { Phase } from '../../../components/Progress/ProgressWithLabel'
import {
  PeerGroupAppointment,
  PeerGroupDetails,
  PeerGroupList,
} from '../../../providers/PeerGroups/types'
import { SectionFields } from '../../../utilities/Forms/types'
import { AddSessionMediaModal } from './partials/AddSessionMediaModal'
import { AddUpdateView } from './partials/AddUpdateView.partial'
import { CancelModal } from './partials/CancelGroup.partial'
import { JoinSession } from './partials/JoinSession.partial'
import { ListView } from './partials/ListView.partial'
import { ViewType } from './types'

interface PeerGroupsViewProps {
  viewMode: ViewType
  setViewMode: React.Dispatch<React.SetStateAction<ViewType>>
  peerGroups: PeerGroupList | null
  peerGroupDetails: PeerGroupDetails | null
  getPeerGroupList: () => Promise<PeerGroupList>
  handleRowClick: (peerGroupId: number) => void
  goToNewPeerGroup: () => void
  setPeerGroupId: React.Dispatch<React.SetStateAction<number | null>>
  peerGroupId: number | null
  peerGroupDetailsEditMode: boolean
  setPeerGroupDetailsEditMode: React.Dispatch<React.SetStateAction<boolean>>
  peerGroupDetailFields: SectionFields
  handleSubmit: UseFormHandleSubmit<FieldValues>
  onSubmit: (values: FieldValues) => Promise<void>
  watchedFields: { name: string; value: any }[] | null
  activePhase: Phase
  submitting: boolean
  reset: UseFormReset<FieldValues>
  showJoinModal: boolean
  setShowJoinModal: React.Dispatch<React.SetStateAction<boolean>>
  showAddSessionMediaModal: boolean
  setShowAddSessionMediaModal: React.Dispatch<React.SetStateAction<boolean>>
  handleJoinSessionModal: () => void
  handleJoinSession: (
    peerGroupSession: PeerGroupAppointment
  ) => Promise<boolean>
  handleCancelPeerGroup: () => void
  setShowCancelModalMode: Dispatch<SetStateAction<boolean>>
  showCancelModalMode: boolean
}

export const PeerGroupsView = ({
  viewMode,
  setViewMode,
  peerGroups,
  handleRowClick,
  goToNewPeerGroup,
  setPeerGroupId,
  peerGroupId,
  peerGroupDetails,
  peerGroupDetailsEditMode,
  peerGroupDetailFields,
  handleSubmit,
  onSubmit,
  watchedFields,
  activePhase,
  submitting,
  reset,
  showJoinModal,
  setShowJoinModal,
  handleJoinSessionModal,
  handleJoinSession,
  showAddSessionMediaModal,
  setShowAddSessionMediaModal,
  showCancelModalMode,
  setShowCancelModalMode,
  handleCancelPeerGroup,
}: PeerGroupsViewProps) => {
  useEffect(() => {
    console.log('viewmode', viewMode)
  }, [])

  return (
    <Box>
      <CancelModal
        handleCancelPeerGroup={handleCancelPeerGroup}
        setShowCancelModalMode={setShowCancelModalMode}
        showCancelModalMode={showCancelModalMode}
      />
      <JoinSession
        showJoinModal={showJoinModal}
        setShowJoinModal={setShowJoinModal}
        handleJoinSession={handleJoinSession}
      />
      <AddSessionMediaModal
        showAddSessionMediaModal={showAddSessionMediaModal}
        setShowAddSessionMediaModal={setShowAddSessionMediaModal}
      />
      {viewMode === ViewType.List && peerGroups && (
        <ListView
          peerGroups={peerGroups}
          handleRowClick={handleRowClick}
          goToNewPeerGroup={goToNewPeerGroup}
          handleJoinSessionModal={handleJoinSessionModal}
        />
      )}
      {viewMode === ViewType.Details &&
        !peerGroupDetailsEditMode &&
        peerGroupDetails && (
          <Box sx={{ position: 'relative' }}>
            <Box sx={{ position: 'absolute', right: '20px', top: '20px' }}>
              <NavigationLink
                label='All peer groups'
                direction='backward'
                handleClick={() => {
                  setPeerGroupId(null)
                  setViewMode(ViewType.List)
                }}
              />
            </Box>
            <AddUpdateView
              peerGroupId={peerGroupId}
              {...{
                peerGroupDetails,
                peerGroupDetailsEditMode,
                viewMode,
                setViewMode,
                setPeerGroupId,
                peerGroupDetailFields,
                watchedFields,
                submitting,
                activePhase,
                reset,
                setShowCancelModalMode,
                showAddSessionMediaModal,
                setShowAddSessionMediaModal,
              }}
            />
          </Box>
        )}
      {viewMode === ViewType.Add && !peerGroupId && (
        <form
          onSubmit={handleSubmit((vals) => {
            return onSubmit(vals)
          })}
        >
          <AddUpdateView
            peerGroupId={null}
            {...{
              peerGroupDetails,
              peerGroupDetailsEditMode,
              viewMode,
              setViewMode,
              setPeerGroupId,
              peerGroupDetailFields,
              watchedFields,
              submitting,
              activePhase,
              reset,
              setShowCancelModalMode,
            }}
          />
        </form>
      )}
      {viewMode === ViewType.Edit && peerGroupId && peerGroupDetails && (
        <form
          onSubmit={handleSubmit((vals) => {
            return onSubmit(vals)
          })}
        >
          <AddUpdateView
            peerGroupId={peerGroupId}
            {...{
              peerGroupDetails,
              peerGroupDetailsEditMode,
              viewMode,
              setViewMode,
              setPeerGroupId,
              peerGroupDetailFields,
              watchedFields,
              submitting,
              activePhase,
              reset,
              setShowCancelModalMode,
              showAddSessionMediaModal,
              setShowAddSessionMediaModal,
            }}
          />
        </form>
      )}
    </Box>
  )
}
