import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { themeColors } from '../../theme'
export const useStyles = makeStyles()((theme: Theme) => ({
  navContainer: {
    width: theme.spacing(13),
    display: 'flex',
    flexDirection: 'row',
    height: theme.spacing(5),
    margin: theme.spacing(0),
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',

    '&:hover': {
      background: themeColors.gray,
      color: themeColors.white,
    },
  },
  navIcon: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  navText: {
    height: '100%',
    marginLeft: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}))
