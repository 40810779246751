import dayjs from 'dayjs'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import { LineChart } from 'react-gifted-charts'

import { drinkCountRecordObj } from '../../providers/DrinkCounter/DrinkCounterProvider'
import { BACObj } from '../../providers/PatientChartPage/BACProvider'
import { themeColors } from '../../theme'
import { getMonthNameByNum } from '../../utilities/HelperFunctions'
import { useStyles } from './PatientChartPage.styles'

interface LineDataItem {
  value: number
  label?: string
  hideDataPoint?: boolean
}
interface LineSegment {
  startIndex: number
  endIndex: number
  color?: string
  thickness?: number
  strokeDashArray?: number[]
}

interface MyJourneyChartInterface {
  timeRangeOptions: string[]
  selectedRecordsDailyAvg: any[]
  selectedTimeRange: string
  setSelectedTimeRange: React.Dispatch<React.SetStateAction<string>>
}

export const MyJourneyChart = ({
  timeRangeOptions,
  selectedRecordsDailyAvg,
  selectedTimeRange,
  setSelectedTimeRange,
}: MyJourneyChartInterface) => {
  const { classes } = useStyles()
  dayjs.extend(weekOfYear)

  const renderXLabels = () => {
    const numOfMonths = Array.from(Array(6).keys())
    const monthNumArr = numOfMonths.map((num) =>
      dayjs().subtract(num, 'month').get('month')
    )
    const monthNameArr = monthNumArr.sort().map((num) => getMonthNameByNum(num))
    switch (selectedTimeRange) {
      case '6m': // past 6 months
        return monthNameArr
      case '1m': {
        //past 31 days
        const numOfDays31 = Array.from(Array(31).keys())
        //could include last month date
        const dayNumArr = numOfDays31.map((num) =>
          dayjs().subtract(num, 'day').format('DD')
        )
        // find the last/this month division index
        const divisionIndex = dayNumArr.findIndex((date) => date === '01')
        const divisionArr = dayNumArr.map((date, i) => {
          const currentMonth = dayjs().month() + 1 // because dayjs().month() starts with 0
          const month = i > divisionIndex ? currentMonth - 1 : currentMonth
          const month2Digits = month < 10 ? `0${month}` : month
          return `${month2Digits}/${date}`
        })
        const ascendingArr = divisionArr.sort((a, b) => {
          const curr = a.split('.').reverse().join()
          const prev = b.split('.').reverse().join()
          return curr < prev ? -1 : curr > prev ? 1 : 0
        })
        // const every5DayArr = divisionArr.filter((_, i) => i % 5 == 0)
        return ascendingArr
      }
      case '1w': {
        const numOfDays7 = Array.from(Array(7).keys())
        const last7DayArr = numOfDays7.map((num) =>
          dayjs().subtract(num, 'day').format('MM/DD/YYYY')
        )
        last7DayArr.sort((a, b) => {
          // sort 'DD.MM.YYYY' array
          const curr = a.split('.').reverse().join()
          const prev = b.split('.').reverse().join()
          return curr < prev ? -1 : curr > prev ? 1 : 0
        })
        return last7DayArr
      }
      default:
        return monthNameArr
    }
  }

  const removeDuplicatesInArr = (arr: any[]) => Array.from(new Set(arr))

  // data arr for <LineChart>
  const renderChartData = () => {
    const labelsArr = renderXLabels()
    const chartDataArr: LineDataItem[] = []
    const getDateWithMonth = (date: Date, months: number) => {
      date.setMonth(date.getMonth() + months)
      return date
    }
    // Average of 1 week (13)
    if (
      selectedTimeRange === '3m' ||
      selectedTimeRange === '6m' ||
      selectedTimeRange === '12m'
    ) {
      const dateXMonthAgoDate = getDateWithMonth(
        new Date(),
        -parseInt(selectedTimeRange)
      )
      const dayJsXMonthAgo = dayjs(dateXMonthAgoDate)
      const currentDate = dayjs()
      const daysCount =
        currentDate.diff(dayJsXMonthAgo, 'date') / (1000 * 60 * 60 * 24)
      //past 3m days
      if (daysCount) {
        const numOfXMDays = Array.from(Array(Math.floor(daysCount)).keys())
        const lastXMouthDateArr = numOfXMDays.map(
          (num) => dayjs().subtract(num, 'day').format('YYYY-MM-DD') //somehow need different format for react native from web
        )
        const weeksOfXMRaw = lastXMouthDateArr.map((date: string) => {
          return `${dayjs(date).get('year')}-${dayjs(date).week()}`
        })

        // arr:  all weeks (removed duplicates) of past 3m/6m/12m; format: [{week: num, year: num}]
        const weeksOfXMNoDuplicates =
          removeDuplicatesInArr(weeksOfXMRaw).reverse()
        const BACdataObjTemp: {
          [index: string]: {
            value: number
            label: string
            hideDataPoint: boolean
          }
        } = {}
        // pre-populate BACdataObjTemp with all week labels
        weeksOfXMNoDuplicates.forEach((yearWeek: string) => {
          const week = yearWeek.split('-')[1]
          const year = yearWeek.split('-')[0]
          BACdataObjTemp[yearWeek] = {
            value: 0,
            label: dayjs(year).week(parseInt(week)).format('MM/DD'),
            hideDataPoint: true,
          }
        })
        // process real data, return an obj {week: {record, record..}}
        const groupRecordsByWeekKey = (selectedRecordsDailyAvg as any[]).reduce(
          (groupedObj: any, eachObj: BACObj | drinkCountRecordObj) => {
            const week = dayjs(eachObj.recordDate).week()
            const year = dayjs(eachObj.recordDate).year()
            if (!groupedObj[`${year}-${week}`]) {
              groupedObj[`${year}-${week}`] = []
            }
            groupedObj[`${year}-${week}`]!.push(eachObj.value)
            return groupedObj
          },
          {}
        )
        // return an obj {year-week1: averageRecord1, year-week2: averageRecord2, ...}
        const weekKeyWithAverageLevels = Object.fromEntries(
          Object.entries(groupRecordsByWeekKey).map(([key, value]: any) => [
            key,
            value.reduce((a: number, e: number) => a + e, 0) / value.length,
          ])
        )
        // modify BACdataObjTemp with actual values
        Object.keys(weekKeyWithAverageLevels).forEach((yearWeek: string) => {
          if (BACdataObjTemp[yearWeek]) {
            BACdataObjTemp[yearWeek]!.value =
              weekKeyWithAverageLevels[yearWeek] || 0
            BACdataObjTemp[yearWeek].hideDataPoint = false
          }
        })
        const renderLabel = (
          yearWeek: string,
          i: number,
          arrLength: number
        ) => {
          const [year, weekStr] = yearWeek.split('-')
          const week = parseInt(weekStr)
          if (i === 0) {
            return dayjs(dateXMonthAgoDate).format('MM/DD/YYYY')
          } else if (i === arrLength - 1) {
            return dayjs(currentDate).format('MM/DD/YYYY')
          } else if (
            selectedTimeRange === '3m' &&
            i === Math.floor(arrLength * 0.5)
          ) {
            return dayjs(year).week(week).format('MM/DD/YYYY')
          } else if (
            selectedTimeRange === '6m' &&
            (i === Math.floor(arrLength * 0.35) ||
              i === Math.floor(arrLength * 0.65))
          ) {
            return dayjs(year).week(week).format('MM/DD/YYYY')
          } else if (
            selectedTimeRange === '12m' &&
            (i === Math.floor(arrLength * 0.35) ||
              i === Math.floor(arrLength * 0.65))
          ) {
            return dayjs(year).week(week).format('MM/DD/YYYY')
          } else return ''
        }
        const arrLength = Object.keys(BACdataObjTemp).length
        Object.keys(BACdataObjTemp).forEach((yearWeek: string, i: number) => {
          chartDataArr.push({
            value: weekKeyWithAverageLevels[yearWeek]
              ? Number(Number(weekKeyWithAverageLevels[yearWeek]).toFixed(2))
              : 0,
            label: renderLabel(yearWeek, i, arrLength),
            hideDataPoint: weekKeyWithAverageLevels[yearWeek] ? false : true,
          })
        })
      }
    }
    // Average of 1 Day (28-31)
    if (selectedTimeRange === '1m') {
      labelsArr.forEach((label: string) => {
        chartDataArr.push({
          value: 0,
          label: label,
          hideDataPoint: true,
        })
        selectedRecordsDailyAvg.forEach((br: BACObj | drinkCountRecordObj) => {
          if (dayjs(br.recordDate).format('MM/DD') === label) {
            const index = chartDataArr.findIndex((dataObj: LineDataItem) => {
              return dataObj.label === label // format: 'MM/DD'
            })
            if (index !== -1) {
              chartDataArr[index].value = br.value
              chartDataArr[index].hideDataPoint = false
            }
          }
        })
      })
      chartDataArr.forEach((obj: LineDataItem, i: number) => {
        if (i % 5 !== 0) {
          obj.label = ''
        }
      })
    }
    // Average of 1 day (7)
    if (selectedTimeRange === '1w') {
      labelsArr.forEach((label: string) => {
        chartDataArr.push({
          value: 0,
          label: label,
          hideDataPoint: true,
        })
        selectedRecordsDailyAvg?.forEach((br: BACObj) => {
          if (dayjs(br.recordDate).format('MM/DD/YYYY') === label) {
            const index = chartDataArr.findIndex((dataObj: LineDataItem) => {
              return dataObj.label === label // 'MM/DD/YYYY'
            })
            chartDataArr[index].value = br.value
            chartDataArr[index].hideDataPoint = false
          }
        })
        chartDataArr.forEach(
          (dataObj: LineDataItem) =>
            (dataObj.label = dataObj.label!.slice(0, 5))
        )
      })
    }
    return chartDataArr
  }

  const renderLineSegments = () => {
    const BACDataArr = renderChartData()
    const lineSegments: LineSegment[] = []
    BACDataArr.forEach((dataObj: LineDataItem, i: number) => {
      if (dataObj.hideDataPoint === true) {
        // push two objects is because we can't have overlapping index objects
        lineSegments.push({
          startIndex: i - 1,
          endIndex: i,
          color: 'transparent',
        })
        lineSegments.push({
          startIndex: i,
          endIndex: i + 1,
          color: 'transparent',
        })
      }
    })
    return lineSegments
  }

  const renderChartXSpacing = () => {
    switch (selectedTimeRange) {
      case '12m': {
        return 10
      }
      case '6m': {
        return 20
      }
      case '3m': {
        return 40
      }
      case '1m': {
        return 20
      }
      case '1w': {
        return 105
      }
      default: {
        return 105
      }
    }
  }

  return (
    <div>
      <section className={classes.timeRangeTabs}>
        {timeRangeOptions.map((option, i) => (
          <button
            className={`${classes.timeRangeTabBtn} ${
              selectedTimeRange === option
                ? classes.timeRangeTabBtnSelected
                : ''
            }`}
            onClick={() => setSelectedTimeRange(option)}
            key={`timeRangeOption-${i}`}
          >
            <span className={classes.timeRangeTabBtnTxt}>{option}</span>
          </button>
        ))}
      </section>
      <section>
        <LineChart
          data={renderChartData()}
          showScrollIndicator
          spacing={renderChartXSpacing()}
          thickness={3}
          width={window.innerWidth * 0.9}
          color={themeColors.blue40}
          backgroundColor={themeColors.white}
          xAxisColor={themeColors.grayWhite}
          xAxisThickness={2}
          yAxisColor={themeColors.grayWhite}
          yAxisThickness={2}
          minValue={0}
          maxValue={
            Math.max(...selectedRecordsDailyAvg.map((i) => i.value)) || 1
          }
          noOfSections={4} // y
          roundToDigits={2} // y
          showFractionalValues={true} // y
          lineSegments={renderLineSegments()}
          // mock
          // lineSegments={[
          //   { startIndex: 0, endIndex: 1, color: 'transparent' }, // 1st segment from 0th to 1st index
          //   // { startIndex: 1, endIndex: 2, color: 'transparent' }, // break (transparent = invisible)
          // ]}
          dataPointsHeight={1}
          dataPointsWidth={1}
          dataPointsColor1={themeColors.blue40}
        />
      </section>
    </div>
  )
}
