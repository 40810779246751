import { ExpandLess, ExpandMore } from '@mui/icons-material'
import {
  Box,
  Collapse,
  List,
  ListItemButton,
  ListItemText,
} from '@mui/material'
import { useState } from 'react'

import { gRemindersEventTypeLink } from '../../../providers/CareTeam/types'
import { themeColors } from '../../../theme'
import { CareTeamPanelProps } from '../types'

export const CareTeamPanel = ({ careTeamEntities }: CareTeamPanelProps) => {
  const [clickedProvider, setClickedProvider] = useState(0)

  const [copiedMeeting, setCopiedMeeting] = useState('')

  const handleClick = (provider: number) => {
    setClickedProvider(clickedProvider ? 0 : provider)
  }

  const copyMeeting = (meeting: {
    eventTypeName: string
    durationInMinutes: number
    scheduleLink: string
  }) => {
    // const htmlContent = `<a href="${meeting.scheduleLink}">${meeting.eventTypeName}<a>`
    navigator.clipboard.writeText(meeting.scheduleLink)
    setCopiedMeeting(meeting.scheduleLink)
    setTimeout(() => {
      setCopiedMeeting('')
    }, 1000)
  }

  const getDuration = (duration: number) => {
    if (duration < 60) {
      return `${duration}m`
    } else if (duration === 60) {
      return '1h'
    } else {
      return `${Math.floor(duration / 60)}h ${Math.floor(duration % 60)}m`
    }
  }

  return (
    <List
      sx={{
        width: '100%',
        maxWidth: '350px',
        maxHeight: '200px',
        overflowY: 'scroll',
        marginBottom: '10px',
      }}
      component='nav'
      aria-labelledby='care-team'
    >
      {careTeamEntities?.map((ct, i) => (
        <div key={`${i}-${ct.providerId}`}>
          <ListItemButton
            onClick={() => {
              handleClick(ct?.providerId)
            }}
            style={{ width: '100%' }}
          >
            <ListItemText
              id={`${ct?.providerId}`}
              primary={ct?.roles[0]?.toUpperCase()}
              primaryTypographyProps={{ fontSize: 14 }}
              secondary={ct?.name}
              secondaryTypographyProps={{
                fontSize: 12,
                color: themeColors.grayblack,
              }}
            />
            {clickedProvider === ct.providerId ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )}
          </ListItemButton>

          <Collapse
            in={ct.providerId === clickedProvider ? true : false}
            timeout='auto'
            unmountOnExit
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              padding: '0',
            }}
          >
            {ct.providerSchedulingLinks.gRemindersEventTypeLinks.map(
              (g: gRemindersEventTypeLink, i) => {
                return (
                  <div
                    key={`${i} - ${g.scheduleLink}`}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      padding: '10px 16px',
                    }}
                  >
                    <span style={{ fontSize: 14, width: '20%' }}>
                      {getDuration(g.durationInMinutes)}
                    </span>

                    <span
                      style={{
                        maxWidth: '50%',
                        color: themeColors.gray50,
                        fontSize: 14,
                        width: '50%',
                      }}
                    >
                      {g.eventTypeName}
                    </span>

                    {!(copiedMeeting === g.scheduleLink) ? (
                      <Box
                        onClick={() => copyMeeting(g)}
                        sx={{
                          fontWeight: 700,
                          fontSize: '10px',
                          lineHeight: '12px',
                          color: themeColors.blue40,
                          cursor: 'pointer',
                        }}
                      >
                        COPY
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          fontWeight: 700,
                          fontSize: '10px',
                          lineHeight: '12px',
                          color: themeColors.green,
                        }}
                      >
                        COPIED!
                      </Box>
                    )}
                  </div>
                )
              }
            )}
          </Collapse>
        </div>
      ))}
    </List>
  )
}
