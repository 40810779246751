import { Box } from '@mui/material'

import { themeColors } from '../../theme'

export interface VerticalLineProps {
  right?: number
  left?: number
}

export const VerticalLine = ({ right, left }: VerticalLineProps) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        ...(right && { right: `${right}px` }),
        ...(left && { left: `${left}px` }),
        top: '5%',
        width: '2px',
        height: '96%',
        backgroundColor: themeColors.gray90,
      }}
    />
  )
}
