import { Check, PriorityHigh } from '@mui/icons-material'
import { Badge, Box, Button, Typography } from '@mui/material'

import VRHeadset from '../../../assets/VRHeadset.png'
import { themeColors } from '../../../theme'

export interface VRHeadsetCheckerProps {
  isHeadsetPaired: boolean
  handlePair: () => void
  handleUnpair: () => void
  unpairError: string | null
}

export const VRHeadsetChecker = ({
  isHeadsetPaired,
  handlePair,
  handleUnpair,
  unpairError,
}: VRHeadsetCheckerProps) => (
  <Box
    sx={{
      marginBottom: 0.5,
      width: '80%',
    }}
  >
    <Typography sx={{ fontWeight: 700, fontSize: 18, marginBottom: 1 }}>
      VR Headset
    </Typography>
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'space-around',
      }}
    >
      <Badge
        badgeContent={
          isHeadsetPaired ? (
            <Check fontSize='small' />
          ) : (
            <PriorityHigh fontSize='small' />
          )
        }
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{
          margin: '10px',
          '& .MuiBadge-badge': {
            color: themeColors.white,
            backgroundColor: isHeadsetPaired
              ? themeColors.green
              : themeColors.red,
            width: '25px',
            height: '25px',
            borderRadius: '100%',
          },
        }}
      >
        <img src={VRHeadset} alt='' style={{ width: '75px', height: '75px' }} />
      </Badge>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 2,
        }}
      >
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: 14,
            lineHeight: '125%',
            width: '100%',
            marginBottom: '10px',
          }}
        >
          {isHeadsetPaired
            ? 'Your VR headset and North-Star Care account are paired.'
            : 'Click Pair to link your VR headset to your North-Star Care account.'}
        </Typography>
        {isHeadsetPaired ? (
          <Button
            variant='outlined'
            onClick={handleUnpair}
            sx={{ width: '70px' }}
          >
            Unpair
          </Button>
        ) : (
          <Button
            variant='contained'
            onClick={handlePair}
            sx={{ width: '70px' }}
          >
            Pair
          </Button>
        )}
      </Box>
    </Box>
    {unpairError && (
      <Typography
        variant='subtitle1'
        sx={{ fontSize: '14px', lineHeight: '19px', color: themeColors.red }}
      >
        {unpairError}
      </Typography>
    )}
  </Box>
)
