interface Props {
  fill: string
}
export const FlagSvg = ({ fill }: Props) => {
  return (
    <svg
      width='15'
      height='15'
      viewBox='0 0 15 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M0 0H1.87466V15H0V0Z' fill={fill} />
      <path
        d='M2.8125 1.87557V10.3129C6.56272 6.56267 11.2498 14.0631 15.0001 10.3129L14.9992 1.87557C11.2499 5.62579 6.56185 -1.87466 2.81252 1.87557H2.8125Z'
        fill={fill}
      />
    </svg>
  )
}
