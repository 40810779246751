import { themeColors } from '../theme'

export const FilterSvg = () => (
  <svg width='16px' height='16px' viewBox='0 0 16 16' fill='none'>
    <path d='M0 3H16V1H0V3Z' fill={themeColors.blue} />
    <path d='M2 7H14V5H2V7Z' fill={themeColors.blue} />
    <path d='M4 11H12V9H4V11Z' fill={themeColors.blue} />
    <path d='M10 15H6V13H10V15Z' fill={themeColors.blue} />
  </svg>
)
