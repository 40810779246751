import { Box, Button, Typography } from '@mui/material'
import { Dispatch, SetStateAction } from 'react'

import { Modal } from '../../../../components'
import { themeColors } from '../../../../theme'

interface JoinVRModalProps {
  showJoinModal: boolean
  setShowJoinModal: Dispatch<SetStateAction<boolean>>
  handleHelpNavigation: () => void
}

export const JoinVRModal = ({
  showJoinModal,
  setShowJoinModal,
  handleHelpNavigation,
}: JoinVRModalProps) => {
  return (
    <Modal
      open={showJoinModal}
      setOpen={setShowJoinModal}
      title={'JOIN VR MEETING'}
      width={'30%'}
      ModalContent={
        <Box sx={{ margin: '25px' }}>
          <Typography
            sx={{ fontSize: '16px', lineHeight: '19px', paddingBottom: '20px' }}
          >
            Put on your headset and navigate to the North-Star Care VR app to
            join the meeting.
          </Typography>
          <Typography
            sx={{
              fontSize: '14px',
              lineHeight: '17px',
              color: themeColors.gray20,
              paddingBottom: '30px',
            }}
          >
            NOTE: Patients cannot see or hear each other until the moderator
            joins the meeting.
          </Typography>
          <Button
            variant='text'
            sx={{
              color: themeColors.blue40,
              fontSize: '14px',
            }}
            onClick={handleHelpNavigation}
          >
            Need help?
          </Button>
        </Box>
      }
    />
  )
}
