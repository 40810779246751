import { useStyles } from './ProgressBar.styles'

const ProgressBar = ({ percent }: { percent: number }) => {
  const { classes } = useStyles()

  let colorStyle
  if (percent < 25) {
    colorStyle = classes.low
  } else if (percent <= 80) {
    colorStyle = classes.medium
  } else if (percent === 100) {
    colorStyle = classes.high
  }

  return (
    <div className={classes.progress}>
      <div
        style={{ width: percent + '%' }}
        className={`${classes.inner} ${colorStyle}`}
      ></div>
    </div>
  )
}

export default ProgressBar
