import { Box, Button, Typography } from '@mui/material'
import { Dispatch, SetStateAction } from 'react'

import { Modal } from '../../../../components'
import { themeColors } from '../../../../theme'

interface CancelModalProps {
  handleCancelPeerGroup: () => void
  setShowCancelModalMode: Dispatch<SetStateAction<boolean>>
  showCancelModalMode: boolean
}

export const CancelModal = ({
  handleCancelPeerGroup,
  setShowCancelModalMode,
  showCancelModalMode,
}: CancelModalProps) => (
  <Modal
    open={showCancelModalMode}
    setOpen={setShowCancelModalMode}
    title={'DELETE PEER GROUP'}
    width={'30%'}
    ModalContent={
      <Box sx={{ width: '92%', margin: 'auto' }}>
        <Typography
          sx={{
            fontSize: '16px',
            lineHeight: '19px',
            color: themeColors.grayblack,
          }}
        >
          Are you sure you want to delete this peer group and all future
          sessions?
        </Typography>
        <Box sx={{ display: 'flex', margin: '7px', alignItems: 'center' }}>
          <Button
            variant='contained'
            onClick={() => setShowCancelModalMode(false)}
            sx={{ marginRight: '10px' }}
          >
            No
          </Button>
          <Typography
            sx={{
              fontSize: '14px',
              lineHeight: '17px',
              color: themeColors.gray20,
            }}
          >
            Cancel and return to previous page
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', margin: '7px', alignItems: 'center' }}>
          <Button
            variant='outlined'
            color='error'
            onClick={() => handleCancelPeerGroup()}
            sx={{ marginRight: '10px' }}
          >
            Yes
          </Button>
          <Typography
            sx={{
              fontSize: '14px',
              lineHeight: '17px',
              color: themeColors.gray20,
            }}
          >
            Permanently delete peer group meeting
          </Typography>
        </Box>
      </Box>
    }
  />
)
