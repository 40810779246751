import { Typography } from '@mui/material'

import { useUser } from '../../providers/User/User.hooks'
import { useStyles } from './Mock.styles'

export interface MockProps {
  route: string
}

export const Mock = ({ route }: MockProps) => {
  const { classes } = useStyles()
  const user = useUser()

  return (
    <div data-testid='mock'>
      <div className={classes.mock}>
        <Typography variant='subtitle1'>You are currently on</Typography>
        <Typography variant='h4'>the {route} page.</Typography>
        <Typography>{`Nice work, ${user?.email ?? 'you'}.`}</Typography>
      </div>
    </div>
  )
}
