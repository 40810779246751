import { Box, LinearProgress, Typography } from '@mui/material'

export type Phase = {
  label: string
  value?: number
  total?: number
}

export type LinearProgressWithLabelProps = {
  progress: number | Phase
}

export const LinearProgressWithLabel = ({
  progress,
}: LinearProgressWithLabelProps) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <Box sx={{ width: '70%', mr: 1.5 }}>
        <LinearProgress
          sx={{
            backgroundColor: `rgb(55, 97, 205,0.4)`,
            '& .MuiLinearProgress-bar': {
              backgroundColor: `rgb(55, 97, 205)`,
            },
          }}
        />
      </Box>
      <Box sx={{ minWidth: '30%', display: 'flex' }}>
        {typeof progress === 'number' ? (
          <Typography variant='body2'>{`${Math.round(progress)}%`}</Typography>
        ) : (progress as Phase).value && (progress as Phase).total ? (
          <Typography sx={{ pl: 1 }} variant='body2'>
            {`${(progress as Phase).label}: ${(progress as Phase).value}/${
              (progress as Phase).total
            }`}
          </Typography>
        ) : (
          <Typography sx={{ pl: 1 }} variant='body2'>
            {`${(progress as Phase).label}`}
          </Typography>
        )}
      </Box>
    </Box>
  )
}
