import { Auth, CognitoUser } from '@aws-amplify/auth'
import { useCallback, useContext, useEffect, useState } from 'react'

import { HelperFuncs } from '../../utilities'
import {
  ChangePasswordInput,
  ConfirmSignUpInput,
  ForgotPasswordInput,
  ResendSignUpInput,
  ResetPasswordInput,
  SignInInput,
  SignUpInput,
} from './types'
import { UserContext } from './User.provider'

export const useAuth = () => {
  const [user, setUser] = useState<CognitoUser | null>(null)
  useEffect(() => {
    let active = true

    const check = async () => {
      try {
        // This is slow for some reason. We need to get the user
        const newUser = await Auth.currentAuthenticatedUser()
        if (active) setUser(newUser)
      } catch (error) {
        if (active) setUser(null)
      }
    }

    check()

    return () => {
      active = false
    }
  }, [setUser])

  const signIn = useCallback(
    async ({ email, password }: SignInInput) => {
      setUser(await Auth.signIn(email, password))
    },
    [setUser]
  )

  const signOut = useCallback(async () => {
    await Auth.signOut()
    setUser(null)
  }, [setUser])

  const deleteUser = useCallback(async () => {
    user?.deleteUser((error?: Error) => {
      if (error) throw error
      setUser(null)
    })
  }, [user, setUser])

  return { user, signIn, signOut, deleteUser }
}

export const useUser = () => {
  const { user } = useContext(UserContext)

  if (!user) return null
  // See https://github.com/aws-amplify/amplify-js/issues/4927
  // @ts-ignore
  return { ...user.attributes }
}

export const useSignOut = () => {
  return useContext(UserContext).signOut
}

export const useSignUpFunctions = () => ({
  signUp: async function signUp({ email }: SignUpInput): Promise<string> {
    const password = HelperFuncs.generateRandomPassword(12)
    await Auth.signUp({
      username: email.toLowerCase(),
      password,
      attributes: { email: email.toLowerCase() },
    })
    return password
  },
  confirmSignUp: async function confirmSignUp({
    email,
    code,
    password,
  }: ConfirmSignUpInput) {
    await Auth.confirmSignUp(email, code)
    await Auth.signIn(email, password)
  },
  resendSignUp: async function resendSignUp({ email }: ResendSignUpInput) {
    await Auth.resendSignUp(email)
  },
})

export const usePasswordFunctions = () => ({
  forgotPassword: async function forgotPassword({
    email,
  }: ForgotPasswordInput) {
    await Auth.forgotPassword(email)
  },
  resetPassword: async function resetPassword({
    email,
    code,
    password,
  }: ResetPasswordInput) {
    await Auth.forgotPasswordSubmit(email, code, password)
  },
  changePassword: async function ({
    password,
    newPassword,
  }: ChangePasswordInput) {
    const user = await Auth.currentAuthenticatedUser()
    await Auth.changePassword(user, password, newPassword)
  },
})
