import { Close } from '@mui/icons-material'
import { Box, IconButton, Modal as MUIModal, Typography } from '@mui/material'

import { themeColors } from '../../theme'

export interface ModalProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  title?: string
  ModalContent: JSX.Element
  width?: string
}

export const Modal = ({
  open,
  setOpen,
  title,
  ModalContent,
  width,
}: ModalProps) => {
  const handleClose = () => setOpen(false)

  return (
    <MUIModal
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box
        sx={{
          width: width || '50%',
          minWidth: '350px',
          minHeigh: '400px',
          overflow: 'hidden',
          borderRadius: '5px',
          backgroundColor: themeColors.white,
        }}
      >
        <Box
          sx={{
            backgroundColor: `${themeColors.gray70}`,
            height: '50px',
            paddingLeft: '20px',
            paddingRight: '20px',
            borderTopLeftRadius: '5px',
            borderTopRightRadius: '5px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Typography>{title}</Typography>
          <IconButton
            size='large'
            aria-controls='join-session-modal'
            onClick={handleClose}
          >
            <Close fontSize='large' />
          </IconButton>
        </Box>
        <Box sx={{ padding: '10px' }}>{ModalContent}</Box>
      </Box>
    </MUIModal>
  )
}
