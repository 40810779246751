import { ScheduleProvider } from '../../providers/Schedule/Schedule.provider'
import { ScheduleView } from './Schedule.view'

const Schedule = () => {
  return (
    <ScheduleProvider>
      <ScheduleView />
    </ScheduleProvider>
  )
}

export { Schedule }
