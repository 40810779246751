import { Person } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'

import { themeColors } from '../../theme'
import { Field } from './types'

export const FieldObjectNonEditable = ({ field }: { field: Field }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: field.minWidthPercent ? `${field.minWidthPercent}%` : '220px',
        minWidth: field.minWidth ? `${field.minWidth}px` : '220px',
        maxWidth: field.maxWidthPercent
          ? `${field.maxWidthPercent}%`
          : field.minWidthPercent
          ? `${field.minWidthPercent}%`
          : '220px',
        minHeight: '60px',
        justifyContent: 'flex-start',
        marginBottom: '10px',
      }}
    >
      <Typography
        sx={{
          fontWeight: 700,
          fontSize: '14px',
          marginBottom: '5px',
        }}
      >
        {field.label}
      </Typography>
      {field.viewComponent ? (
        <Box>{field.viewComponent}</Box>
      ) : (
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '14px',
            color: themeColors.gray40,
          }}
        >
          {field.initialValue?.toString()}
        </Typography>
      )}
    </Box>
  )
}

export const FieldObjectEditable = ({ field }: { field: Field }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: field.minWidthPercent ? `${field.minWidthPercent}%` : '220px',
        minWidth: field.minWidth ? `${field.minWidth}px` : '220px',
        maxWidth: field.maxWidthPercent
          ? `${field.maxWidthPercent}%`
          : field.minWidthPercent
          ? `${field.minWidthPercent}%`
          : '220px',
        minHeight: '80px',
        height: '100%',
        justifyContent: 'flex-start',
        marginBottom: '10px',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: '14px',
          }}
        >
          {field.label} {field.required && '*'}{' '}
        </Typography>
        {field.isPatientVisible && <Person fontSize='small' />}
      </Box>
      <Box sx={{ width: '85%', height: 'min-content' }}>
        {field.editComponent}
      </Box>
      {!field.isEditable && (
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: '12px',
            color: themeColors.gray50,
          }}
        >
          ** Must be changed in EMR
        </Typography>
      )}
    </Box>
  )
}
