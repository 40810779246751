import { createContext, ReactNode, useState } from 'react'

import { useAxios } from '../../utilities/Requests/useAxios'
import { AccessRoleList } from '../ProvidersProvider/types'
import { BACObj } from './BACProvider'

export interface patientObj {
  patientId: number
  patientName: string
  dateOfBirth: string
  admissionDate: string
  dischargeDate: string
  mrn: string
  accessRoles: AccessRoleList | null
  bacHistory: BACObj[]
}

export interface PatientChartPageContextInterface {
  activeChartPatient: patientObj
  setActiveChartPatient: React.Dispatch<React.SetStateAction<patientObj>>
  getPatientDetails: (patientId: number) => Promise<any>
}

export const PatientChartPageContext =
  createContext<PatientChartPageContextInterface | null>(null)

export const PatientChartPageProvider = ({
  children,
}: {
  children?: ReactNode
}) => {
  const [activeChartPatient, setActiveChartPatient] = useState<patientObj>({
    patientId: 0,
    patientName: '',
    dateOfBirth: '',
    admissionDate: '',
    dischargeDate: '',
    mrn: '',
    accessRoles: [],
    bacHistory: [],
  })

  const { fetch } = useAxios()

  const getPatientDetails = async (patientId: number) => {
    const { data } = await fetch({
      path: `Provider/GetPatientDetails?PatientId=${patientId}`,
    })

    if (data) {
      setActiveChartPatient(data)
      console.log('getPatientDetails-success')
    }
  }

  return (
    <PatientChartPageContext.Provider
      value={{ activeChartPatient, setActiveChartPatient, getPatientDetails }}
    >
      {children}
    </PatientChartPageContext.Provider>
  )
}
