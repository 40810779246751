import React, { useState } from 'react'

import { useAxios } from '../../utilities/Requests/useAxios'

export type drinkCountRecordObj = {
  id: number
  value: number
  goal: number
  recordDate: string
  patientDrinkTypeId: number
}

export type PatientDrinkType = {
  name: string
  alcoholContent: number
  drinkOunces: number
}

export interface DrinkCounterContextInterface {
  drinkCountRecords: drinkCountRecordObj[]
  getPatientDrinkCountRecords: (
    patientId: number
  ) => Promise<drinkCountRecordObj[]>
  patientDrinkTypes: PatientDrinkType[]
  getPatientDrinkTypes: (patientId: number) => Promise<PatientDrinkType[]>
  resetPatientDrinkTypes: (patientId: number) => Promise<any>
}

export const DrinkCounterContext =
  React.createContext<DrinkCounterContextInterface | null>(null)

export const DrinkCounterProvider = ({ children }: any) => {
  const { fetch } = useAxios()

  const [drinkCountRecords, setDrinkCountRecords] = useState<
    drinkCountRecordObj[]
  >([])
  const [patientDrinkTypes, setPatientDrinkTypes] = useState<
    PatientDrinkType[]
  >([])

  const getPatientDrinkCountRecords = async (patientId: number) => {
    const { data, error } = await fetch({
      path: `DrinkCounter/GetPatientDrinkCountRecordsProvider?patientId=${patientId}`,
    })
    if (data) {
      setDrinkCountRecords(data)
      return data
    } else {
      console.warn(`getPatientDrinkCountRecords-error: ${error}`)
    }
  }

  const getPatientDrinkTypes = async (patientId: number) => {
    const { data, error } = await fetch({
      path: `DrinkCounter/GetPatientDrinkTypesProvider?patientId=${patientId}`,
    })
    if (data) {
      setPatientDrinkTypes(data)
      return data
    } else {
      console.warn(`getPatientDrinkTypesProvider-error: ${error}`)
    }
  }

  const resetPatientDrinkTypes = async (patientId: number) => {
    const { error } = await fetch({
      path: `DrinkCounter/ResetPatientDrinkTypes?patientId=${patientId}`,
      methodType: 'PUT',
    })
    if (error) {
      console.warn(`resetPatientDrinkTypes-error: ${error}`)
    }
  }

  return (
    <DrinkCounterContext.Provider
      value={{
        drinkCountRecords,
        getPatientDrinkCountRecords,
        patientDrinkTypes,
        getPatientDrinkTypes,
        resetPatientDrinkTypes,
      }}
    >
      {children}
    </DrinkCounterContext.Provider>
  )
}
