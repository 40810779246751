import { Box, Button, Grid, Typography } from '@mui/material'

import { FormInstructions, NavigationLink } from '../../components'
import {
  FieldObjectEditable,
  FieldObjectNonEditable,
} from '../../utilities/Forms/FieldObject.partial'
import { Field, SectionFields } from '../../utilities/Forms/types'
import { Section } from '../../utilities/Forms/types'
import { PairHeadSetModal } from './partials/PairHeadSetModal.partial'
import { VisibleProfile } from './partials/VisibleProfile.partial'
import { VRHeadsetChecker } from './partials/VRHeadsetChecker.partial'
import { SaveProgress } from './types'

interface ProfileViewProps {
  isHeadsetPaired: boolean
  user: any
  setUserImage: (canvasEl: HTMLCanvasElement) => void
  saveProgress: SaveProgress
  editPhotoMode: boolean
  setEditPhotoMode: React.Dispatch<React.SetStateAction<boolean>>
  providerDetailFields: SectionFields
  handlePair: () => void
  handleUnpair: () => void
  profileEditMode: boolean
  setProfileEditMode: React.Dispatch<React.SetStateAction<boolean>>
  showPairModal: boolean
  setShowPairModal: React.Dispatch<React.SetStateAction<boolean>>
  unpairError: string | null
}

export const ProfileView = ({
  isHeadsetPaired,
  user,
  setUserImage,
  saveProgress,
  editPhotoMode,
  setEditPhotoMode,
  providerDetailFields,
  handlePair,
  handleUnpair,
  profileEditMode,
  setProfileEditMode,
  showPairModal,
  setShowPairModal,
  unpairError,
}: ProfileViewProps) => {
  const handleEditImageModeToggle = (onOrOff: 'on' | 'off') => {
    setEditPhotoMode(onOrOff === 'on' ? true : false)
  }

  return (
    <Box data-testid='profile' sx={{ height: '100%' }}>
      {showPairModal && (
        <PairHeadSetModal {...{ showPairModal, setShowPairModal }} />
      )}
      <Grid container sx={{ height: '100%' }}>
        {!profileEditMode && (
          <Grid item md={2} sm={12} sx={{ width: '90%', margin: 'auto' }}>
            <VisibleProfile
              {...{
                editPhotoMode,
                setUserImage,
                user,
                handleEditImageModeToggle,
                saveProgress,
                setEditPhotoMode,
              }}
            />
          </Grid>
        )}
        <Grid item md={!profileEditMode ? 10 : 12} sm={12}>
          <Box
            sx={{
              width: '100%',
              paddingLeft: 7,
              paddingRight: 2,
              display: 'flex',
              justifyContent: 'space-around',
              height: '100%',
            }}
          >
            <Box
              id='column-left'
              sx={{
                width: `${profileEditMode ? '100%' : '75%'}`,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
              }}
            >
              {providerDetailFields.map((section: Section) => (
                <Box
                  key={section.sectionLabel}
                  sx={{
                    display: 'flex',
                    width: '100%',
                  }}
                >
                  <Box
                    sx={{
                      marginBottom: 0.5,
                      width: '100%',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography
                        sx={{ fontWeight: 700, fontSize: 18, marginBottom: 1 }}
                      >
                        {section.sectionLabel}
                      </Typography>
                      {!!section.hasInstructions && profileEditMode && (
                        <FormInstructions />
                      )}
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                      }}
                    >
                      {section.fields.map((field: Field) => {
                        const fieldsWithValues: Field = {
                          ...field,
                          initialValue: field.displayKey
                            ? user[field.displayKey]
                            : user[field.backendKey],
                        }
                        return !profileEditMode ? (
                          <FieldObjectNonEditable
                            field={fieldsWithValues}
                            key={field.label}
                          />
                        ) : (
                          <FieldObjectEditable
                            field={fieldsWithValues}
                            key={field.label}
                          />
                        )
                      })}
                    </Box>
                  </Box>
                </Box>
              ))}
              {!profileEditMode ? (
                <Box sx={{ display: 'flex', margin: '10px' }}>
                  <Button
                    variant='contained'
                    onClick={() => setProfileEditMode(true)}
                  >
                    Edit Profile
                  </Button>
                </Box>
              ) : (
                <Box sx={{ display: 'flex', margin: '10px' }}>
                  <NavigationLink
                    label='Cancel'
                    direction='backward'
                    handleClick={() => setProfileEditMode(false)}
                  />
                  <Box sx={{ display: 'flex', margin: '10px' }}>
                    <Button variant='contained' type='submit'>
                      Save
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
            {!profileEditMode && (
              <Box
                id='column-right'
                sx={{
                  display: 'flex',
                  width: '25%',
                  height: '75%',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end',
                }}
              >
                <VRHeadsetChecker
                  {...{
                    isHeadsetPaired,
                    handlePair,
                    handleUnpair,
                    unpairError,
                  }}
                />
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
