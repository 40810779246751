import { Box, Button, Typography } from '@mui/material'

import UserDefault from '../../../assets/User.png'
import { themeColors } from '../../../theme'

export interface ViewImageProps {
  user: any
  setEditMode: (editMode: boolean) => void
}

export const ViewImage = ({ user, setEditMode }: ViewImageProps) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      alignItems: 'center',
      marginTop: '10px',
    }}
  >
    <Box>
      <Box
        component='img'
        sx={{
          height: 250,
          width: 250,
          maxHeight: { xs: 50, sm: 100, md: 150, lg: 175 },
          maxWidth: { xs: 50, sm: 100, md: 150, lg: 175 },
          borderRadius: '50%',
        }}
        alt='Profile Photo'
        src={user?.photoUrl || UserDefault}
      />
      <Button
        variant='text'
        sx={{ color: themeColors.blue40, margin: '10px auto' }}
        onClick={() => setEditMode(true)}
        disableFocusRipple
      >
        Edit photo
      </Button>
    </Box>
    <Typography
      sx={{
        fontWeight: 900,
        fontSize: '20px',
        lineHeight: '24px',
        textAlign: 'center',
      }}
    >
      {user.preferredFirstName || user.firstName} {user.lastName}
    </Typography>
    <Typography
      sx={{
        fontWeight: 500,
        fontSize: '15px',
        lineHeight: '18px',
        textAlign: 'center',
      }}
    >
      {user?.credentials?.toUpperCase()}
    </Typography>
    <Typography
      sx={{
        marginTop: '20px',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '17px',
      }}
    >
      {user.bio}
    </Typography>
  </Box>
)
