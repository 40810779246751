import { useEffect, useState } from 'react'

import { Widget } from '../../components'
import { Widget as WidgetType } from './Widgets/types'

interface DashboardViewProps {
  widgets: WidgetType[]
  seedParams: {
    messageThreadId: string | null
  }
}

export const DashboardView = ({ widgets }: DashboardViewProps) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '100%',
        height: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          width: windowWidth < 1000 ? '100%' : '45%',
        }}
      >
        {/* schedule & announcement widgets */}
        {widgets.map((widget, i) => {
          const { Provider, Buttons } = widget
          if (i < 2) {
            return (
              <section
                key={widget.name}
                style={{
                  height: '48%',
                }}
              >
                <Provider>
                  <Widget
                    title={widget.name}
                    Buttons={Buttons}
                    usePadding={widget.usePadding}
                  >
                    <>{widget.element}</>
                  </Widget>
                </Provider>
              </section>
            )
          } else return null
        })}
      </div>
      {/* message widget */}
      {widgets.map((widget, i) => {
        const { Provider, Buttons } = widget
        if (i === 2) {
          return (
            <section
              key={widget.name}
              style={{
                height: '100%',
                width: windowWidth < 1000 ? '100%' : '53%',
                marginLeft: windowWidth < 1000 ? 0 : '20px',
              }}
            >
              <Provider>
                <Widget
                  title={widget.name}
                  Buttons={Buttons}
                  usePadding={widget.usePadding}
                >
                  <>{widget.element}</>
                </Widget>
              </Provider>
            </section>
          )
        } else return null
      })}
    </div>
  )
}
