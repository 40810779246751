import { Storage } from 'aws-amplify'
import React from 'react'
import { useState } from 'react'

import { useAxios } from '../../utilities/Requests/useAxios'

export interface BACObj {
  id: number
  recordDate: string
  value: number
  reviewed: boolean
  reviewedBy: string
  videoStorageKey: string
  videoURL: string
}

export interface BACObjForChart {
  id: number
  recordDate: string
  value: number
  hide: boolean
}

export interface bacRecordSendDataObj {
  level: number
}
export interface BACContextInterface {
  bacRecords: BACObj[]
  bacDailyAverages: BACObj[]
  getBacRecords: (patientId: number) => Promise<any>
  getPatientBacDailyAverages: (patientId: number) => Promise<any>
  reviewBacRecord: (BacRecordId: number) => Promise<void>
  bacRecordsWithVideo: BACObj[]
  setBacRecordsWithVideo: React.Dispatch<React.SetStateAction<BACObj[]>>
}

export const BACContext = React.createContext<BACContextInterface | null>(null)

export const BACProvider = ({ children }: any) => {
  const { fetch } = useAxios()

  const [bacRecords, setBacRecords] = useState<BACObj[]>([])

  const [bacDailyAverages, setBacDailyAverages] = useState<BACObj[]>([])

  const [bacRecordsWithVideo, setBacRecordsWithVideo] = useState<BACObj[]>([])

  const getBacRecords = async (patientId: number) => {
    const { data, error } = await fetch({
      path: `BacRecord/GetPatientBacRecords?patientId=${patientId}`,
    })
    if (data) {
      setBacRecords(
        data.sort(
          (br1: BACObj, br2: BACObj) =>
            new Date(br1.recordDate).getTime() -
            new Date(br2.recordDate).getTime()
        )
      ) //get bac records from earliest time to latest time
      const dataWithVideoPromises = data.map(async (r: BACObj) => {
        if (r.videoStorageKey) {
          r.videoURL = await Storage.get(r.videoStorageKey)
        }
        return r
      })
      const dataWithVideo = await Promise.all(dataWithVideoPromises)
      setBacRecordsWithVideo(dataWithVideo.filter((r: BACObj) => r.videoURL))
      return data.sort(
        (br1: BACObj, br2: BACObj) =>
          new Date(br2.recordDate).getTime() -
          new Date(br1.recordDate).getTime()
      )
    } else if (error) {
      throw new Error(`Error in getting BAC records.`)
    }
  }

  const getPatientBacDailyAverages = async (patientId: number) => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const { data, error } = await fetch({
      path: `BacRecord/GetPatientBacDailyAverages?patientId=${patientId}&timezone=${timezone}`,
    })

    if (data) {
      setBacDailyAverages(
        data.sort(
          (br1: BACObj, br2: BACObj) =>
            new Date(br1.recordDate).getTime() -
            new Date(br2.recordDate).getTime()
        )
      ) //get bac records from earliest time to latest time
      return data
    } else if (error) {
      throw new Error(`Error in getting BAC DailyAverages.`)
    }
  }

  const reviewBacRecord = async (BacRecordId: number) => {
    const { error } = await fetch({
      path: `BacRecord/ReviewBacRecord?BacRecordId=${BacRecordId}`,
      methodType: 'PUT',
    })
    if (error) {
      throw new Error(`Error in reviewBacRecord,`, error)
    }
  }

  return (
    <BACContext.Provider
      value={{
        bacRecords,
        getBacRecords,
        bacDailyAverages,
        getPatientBacDailyAverages,
        reviewBacRecord,
        bacRecordsWithVideo,
        setBacRecordsWithVideo,
      }}
    >
      {children}
    </BACContext.Provider>
  )
}
