import { Box, Typography } from '@mui/material'
import { startCase } from 'lodash'

import { themeColors } from '../../../theme'
import { Contact, ContactTabPanelProps } from '../types'

export const ContactTabPanel = ({ contact }: ContactTabPanelProps) => {
  return (
    <Box>
      {[...Object.keys(contact!)].map((contactRow: string) => (
        <Box
          key={contactRow}
          sx={{
            width: '100%',
            display: 'flex',
            marginBottom: 1,
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '14px',
              color: themeColors.grayblack,
            }}
          >
            {startCase(contactRow || '')}:
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '14px',
              color: themeColors.gray40,
            }}
          >
            {contact && contact[contactRow as keyof Contact]}
          </Typography>
        </Box>
      ))}
    </Box>
  )
}
