import { Box } from '@mui/material'
import { useEffect, useState } from 'react'

import { Modal, ScheduleView } from '../../../../components'
import { usePeerGroups } from '../../../../providers/PeerGroups/PeerGroups.provider'
import {
  PeerGroupAppointment,
  PeerGroupScheduleDay,
} from '../../../../providers/PeerGroups/types'
import { divideScheduleIntoDays } from '../../../../utilities/HelperFunctions'

interface JoinSessionProps {
  setShowJoinModal: React.Dispatch<React.SetStateAction<boolean>>
  showJoinModal: boolean
  handleJoinSession: (
    peerGroupSession: PeerGroupAppointment
  ) => Promise<boolean>
}

export const JoinSession = ({
  showJoinModal,
  setShowJoinModal,
  handleJoinSession,
}: JoinSessionProps) => {
  const { peerGroupSchedule, getPeerGroupSchedule } = usePeerGroups()
  const [peergroupAppointmentsByDay, setPeergroupAppointmentsByDay] = useState<
    PeerGroupScheduleDay[]
  >([])
  const [activeDay, setActiveDay] = useState<Date | null>(new Date())
  useEffect(() => {
    if (activeDay) {
      getPeerGroupSchedule(activeDay)
    }
  }, [activeDay])

  useEffect(() => {
    if (peerGroupSchedule) {
      setPeergroupAppointmentsByDay(
        divideScheduleIntoDays(peerGroupSchedule, [] as PeerGroupScheduleDay[])
      )
    }
  }, [peerGroupSchedule])

  return (
    <Modal
      open={showJoinModal}
      setOpen={setShowJoinModal}
      title={'ADD SESSION TO SCHEDULE'}
      ModalContent={
        <Box>
          {peergroupAppointmentsByDay && (
            <ScheduleView
              activeDay={activeDay}
              schedule={peergroupAppointmentsByDay}
              setActiveDay={setActiveDay}
              selectAppointment={(appointment) =>
                handleJoinSession(appointment as PeerGroupAppointment)
              }
            />
          )}
        </Box>
      }
    />
  )
}
