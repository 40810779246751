import { CognitoUserSession } from 'amazon-cognito-identity-js'
import { Auth } from 'aws-amplify'
import axios, { AxiosError, AxiosRequestConfig, Method } from 'axios'

const { REACT_APP_API_CONFIG_PATH } = process.env

export interface Request {
  path: string
  methodType?: Method
  authenticationRequired?: boolean
  body?: any
  mapper?: (dataIn: any) => any
  fetchCondition?: boolean
  triggerOn?: any
}

export interface Response {
  data: any
  error: AxiosError | null
}

export const useAxios = () => {
  const fetch = async ({
    path,
    methodType = 'get',
    authenticationRequired = true,
    body,
    mapper = (x) => x,
    fetchCondition = true,
  }: Request): Promise<Response> => {
    const abortController = new AbortController()
    const response: Response = { data: null, error: null }

    let requestConfig: AxiosRequestConfig = {
      baseURL: `${REACT_APP_API_CONFIG_PATH}/api/`,
      timeout: 10000,
      headers: {
        'Content-Type': 'application/json',
        'X-NSC-Signature': process.env.REACT_APP_SIGNATURE_KEY!,
      },
      data: body,
      url: path,
      method: methodType,
    }
    const user = await Auth.currentAuthenticatedUser()

    if (authenticationRequired) {
      if (user) {
        user.getSession((error: null, session: CognitoUserSession) => {
          if (error) {
            response.error = new AxiosError(
              'Use must be logged in to make this request'
            )
          }

          const authenticatedConfig = {
            ...requestConfig,
            headers: {
              ...requestConfig.headers,
              Authorization: `Bearer ${session.getIdToken().getJwtToken()}`,
              'X-NSC-Signature':
                'nscNt35nXimp5SUV9mQPDvL5ab9X7h4RC2RZsl2cbnKlLJxIdjJijt5j9HJUIvvB',
            },
          }
          requestConfig = { ...requestConfig, ...authenticatedConfig }
        })
      } else {
        response.error = new AxiosError(
          'Use must be logged in to make this request'
        )
      }
    }

    const fetchData = async () => {
      try {
        const fetchResponse = await axios({
          ...requestConfig,
          signal: abortController.signal,
        })
        const results = mapper(fetchResponse.data)
        response.data = results
      } catch (e: unknown) {
        console.error(e)
        response.error = e as AxiosError
      } finally {
        abortController.abort()
      }
    }

    if (fetchCondition && (authenticationRequired ? user : true)) {
      await fetchData()
    }

    return response
  }

  return { fetch }
}
