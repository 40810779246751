import { FieldValues } from 'react-hook-form'

import { Options } from '../../providers/Options/types'
import {
  PeerGroupDetails,
  PeerGroupMedia,
} from '../../providers/PeerGroups/types'
import { syncDateAndTime } from '../HelperFunctions'

interface Mapper {
  ToAPI: (values: FieldValues, baseValue: any, options: Options[]) => any
  FromAPI: (response: any) => any
}

export const PeerGroupMapper: Mapper = {
  ToAPI: (
    values: FieldValues,
    baseValue: PeerGroupDetails,
    options: Options[]
  ) => {
    const updatedPeerGroup: PeerGroupDetails = {
      ...baseValue,
      ...values,
    }
    // Mapping -> API
    delete updatedPeerGroup.imageUrlForDisplay
    delete updatedPeerGroup.mediaForDisplay

    updatedPeerGroup.minutesBefore = Number(updatedPeerGroup.minutesBefore)
    updatedPeerGroup.minutesAfter = Number(updatedPeerGroup.minutesAfter)
    delete (updatedPeerGroup as any)['minutesBefore,minutesAfter']

    updatedPeerGroup.startDate = syncDateAndTime(
      updatedPeerGroup.startDate!,
      updatedPeerGroup.startTime!
    )

    if (updatedPeerGroup.endDate) {
      updatedPeerGroup.endDate = syncDateAndTime(
        updatedPeerGroup.endDate,
        updatedPeerGroup.endTime!
      )
    }

    updatedPeerGroup.observers = updatedPeerGroup.observers || []

    updatedPeerGroup.backgroundImageId =
      updatedPeerGroup.backgroundImageId || options[0].apiId
    // END Mapping -> API

    updatedPeerGroup.media =
      [...(updatedPeerGroup.media || [])].filter((m) => m && !m.markToDelete) ||
      []

    return updatedPeerGroup
  },
  FromAPI: (peerGroup: PeerGroupDetails) => {
    const mappedPeerGroup: PeerGroupDetails = { ...peerGroup }
    mappedPeerGroup.media = [...(mappedPeerGroup.media || [])].map(
      ({ id, is360, fileType, fileKey, fileName }): PeerGroupMedia => ({
        id,
        is360,
        fileType,
        fileKey,
        fileName,
        markToDelete: false,
      })
    )

    return mappedPeerGroup
  },
}
