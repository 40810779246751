import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { themeColors } from '../../theme'

export const useStyles = makeStyles()((theme: Theme) => ({
  progress: {
    width: '100%',
    background: '#e1e1e1',
    height: '0.4rem',
    marginTop: '0.75rem',
    marginBottom: '1rem',
  },
  inner: {
    height: '100%',
  },
  low: {
    background: themeColors.red,
    width: '66%',
  },
  medium: {
    background: themeColors.orange,
    width: '66%',
  },
  high: {
    background: themeColors.green,
    width: '66%',
  },
}))
