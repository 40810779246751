import { ReactNode } from 'react'

import { useProviderDetails } from '../../providers/ProviderDetails/ProviderDetails.provider'
import { MainView } from './Main.view'

const Main = ({
  usePaper,
  children,
}: {
  usePaper?: boolean
  children?: ReactNode
}) => {
  const {
    myProviderDetails,
    updateProviderOnlineStatus,
    getProviderOnlineStatus,
  } = useProviderDetails()

  return (
    <MainView
      usePaper={usePaper}
      myProviderDetails={myProviderDetails}
      updateProviderOnlineStatus={updateProviderOnlineStatus}
      getProviderOnlineStatus={getProviderOnlineStatus}
    >
      {children}
    </MainView>
  )
}

export { Main }
